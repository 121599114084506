const actions = {
  READ_PROFILE: 'READ_PROFILE',
  PROFILE_FRIENDS_BEGIN: 'PROFILE_FRIENDS_BEGIN',
  PROFILE_FRIENDS_SUCCESS: 'PROFILE_FRIENDS_SUCCESS',
  PROFILE_FRIENDS_ERR: 'PROFILE_FRIENDS_ERR',

  POST_DATA_BEGIN: 'POST_DATA_BEGIN',
  POST_DATA_SUCCESS: 'POST_DATA_SUCCESS',
  POST_DATA_ERR: 'POST_DATA_ERR',

  PERMISSIONS_SUCCESS: 'PERMISSIONS_SUCCESS',

  ACTION_BEGIN: 'ACTION_BEGIN',
  ERROR: 'ERROR',

  permissionsSuccess: data => {
    return {
      type: actions.PERMISSIONS_SUCCESS,
      data,
    };
  },

  readProfileSuccess: data => {
    return {
      type: actions.READ_PROFILE,
      data,
    };
  },

  profileFriendsBegin: () => {
    return {
      type: actions.PROFILE_FRIENDS_BEGIN,
    };
  },

  profileFriendsSuccess: data => {
    return {
      type: actions.PROFILE_FRIENDS_SUCCESS,
      data,
    };
  },

  profileFriendsErr: err => {
    return {
      type: actions.PROFILE_FRIENDS_ERR,
      err,
    };
  },

  postDataBegin: () => {
    return {
      type: actions.POST_DATA_BEGIN,
    };
  },

  postDataSuccess: data => {
    return {
      type: actions.POST_DATA_SUCCESS,
      data,
    };
  },

  postDataErr: err => {
    return {
      type: actions.POST_DATA_ERR,
      err,
    };
  },

  actionBegin: data => {
    return { type: actions.ACTION_BEGIN, data };
  },
  error: err => {
    return { type: actions.ERROR, err };
  },
};

export default actions;
