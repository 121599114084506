import actions from './actions';

const { GET_BANKS, GET_BANK_BY_ID, CLEAN_BANK, ERROR, ACTION_BEGIN } = actions;

const initialState = {
  banks: {},
  bank: {},
  loading: false,

  error: null,
};

const bankReducer = (state = initialState, action) => {
  const { type, data, err } = action;

  switch (type) {
    case GET_BANKS:
      return {
        ...state,
        banks: data.banks,
        loading: false,
      };
    case GET_BANK_BY_ID:
      return {
        ...state,
        bank: data.bank,
        loading: false,
      };
    case CLEAN_BANK:
      return {
        ...state,
        bank: {},
      };
    case ERROR:
      return {
        ...state,
        error: err,
        loading: false,
      };

    case ACTION_BEGIN:
      const stateClone = { ...state, ...data };
      return {
        ...stateClone,
      };

    default:
      return state;
  }
};

export default bankReducer;
