import actions from './actions';

const {
  GET_PERMISSIONS,

  GET_ADMINS,
  GET_ADMIN_BY_ID,
  CLEAN_ADMIN,

  ACTION_BEGIN,
  ERROR,
} = actions;

const initialState = {
  permissions: [],

  admins: {},
  admin: {},

  loadingPermissions: false,
  loadingAdmins: false,
  loading: false,
  error: null,
};

const permissionsReducer = (state = initialState, action) => {
  const { type, data, err } = action;

  switch (type) {
    case GET_PERMISSIONS:
      return {
        ...state,
        permissions: data,
        loadingPermissions: false,
      };

    case GET_ADMINS:
      return {
        ...state,
        admins: data,
        loadingAdmins: false,
      };
    case GET_ADMIN_BY_ID:
      return {
        ...state,
        admin: data,
        loadingAdmins: false,
      };
    case CLEAN_ADMIN:
      return {
        ...state,
        admin: {},
      };
    case ACTION_BEGIN:
      const stateClone = { ...state, ...data };
      return {
        ...stateClone,
      };
    case ERROR:
      return {
        ...state,
        error: err,
        loading: false,
        loadingAdmins: false,
        loadingPermissions: false,
      };
    default:
      return state;
  }
};

export default permissionsReducer;
