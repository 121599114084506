const actions = {
  GET_USERS: 'GET_USERS',
  GET_USER_BY_ID: 'GET_USER_BY_ID',
  CLEAN_USER: 'CLEAN_USER',

  ACTION_BEGIN: 'ACTION_BEGIN',
  ERROR: 'ERROR',

  actionBegin: data => {
    return { type: actions.ACTION_BEGIN, data };
  },

  usersSuccess: data => {
    return { type: actions.GET_USERS, data };
  },
  userSuccess: data => {
    return { type: actions.GET_USER_BY_ID, data };
  },
  cleanUserAction: () => {
    return { type: actions.CLEAN_USER };
  },

  error: err => {
    return { type: actions.ERROR, err };
  },
};

export default actions;
