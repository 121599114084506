import actions from './actions';

const initialState = {
  loading: false,
  error: null,
  postLoading: false,
  userSettings: {},
  permissions: [],
  loadingUpdate: false,
};

const {
  READ_PROFILE,
  PROFILE_FRIENDS_BEGIN,
  PROFILE_FRIENDS_SUCCESS,
  PROFILE_FRIENDS_ERR,
  POST_DATA_BEGIN,
  POST_DATA_SUCCESS,
  PERMISSIONS_SUCCESS,

  POST_DATA_ERR,
  ACTION_BEGIN,
  ERROR,
} = actions;

const ProfileReducer = (state = initialState, action) => {
  const { type, data, err } = action;
  switch (type) {
    case READ_PROFILE:
      return {
        ...state,
        userSettings: data,
      };
    case PROFILE_FRIENDS_BEGIN:
      return {
        ...state,
        sLoading: true,
      };
    case PROFILE_FRIENDS_SUCCESS:
      return {
        ...state,
        friends: data,
        sLoading: false,
      };
    case PROFILE_FRIENDS_ERR:
      return {
        ...state,
        error: err,
        sLoading: false,
      };
    case POST_DATA_BEGIN:
      return {
        ...state,
        postLoading: true,
      };
    case POST_DATA_SUCCESS:
      return {
        ...state,
        posts: data,
        postLoading: false,
      };
    case POST_DATA_ERR:
      return {
        ...state,
        error: err,
        postLoading: false,
      };
    case PERMISSIONS_SUCCESS:
      return {
        ...state,
        permissions: data,
      };
    case ACTION_BEGIN:
      const stateClone = { ...state, ...data };
      return {
        ...stateClone,
      };
    case ERROR:
      return {
        ...state,
        error: err,
        loadingUpdate: false,
      };
    default:
      return state;
  }
};

export default ProfileReducer;
