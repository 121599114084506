import React, { lazy, Suspense } from 'react';
import { Switch, Route } from 'react-router-dom';
import { Spin } from 'antd';
import WithEcommerceLayout from '../layout/withEcommerceLayout';
import ScrollToTop from './scrollToTop';
import { useTranslation } from 'react-i18next';
const Home = lazy(() => import('../container/ecommerce/Home'));

const ProductForm = lazy(() => import('../container/ecommerce/product/ProductForm'));
const Invoice = lazy(() => import('../container/ecommerce/Invoice'));
const Sellers = lazy(() => import('../container/ecommerce/Sellers'));
const ShoppingCart = lazy(() => import('../container/ecommerce/Cart'));
const LoginCustomerView = lazy(() => import('../container/ecommerce/Login'));
const Address = lazy(() => import('../container/ecommerce/Address'));
const CustomerProfile = lazy(() => import('../container/ecommerce/CustomerProfile'));
const ValidateToken = lazy(() => import('../container/ecommerce/ValidateToken'));
const PaymentLinkView = lazy(() => import('../container/ecommerce/PaymentLink'));
const PaymentInvoiceView = lazy(() => import('../container/ecommerce/PaymentInvoice'));
const LoginAsAdminView = lazy(() => import('../container/ecommerce/LoginAsAdmin'));
const OrderTracking = lazy(() => import('../container/ecommerce/OrderTracking'));
const ApprovalView = lazy(() => import('../container/ecommerce/product/Approval'));

const EcommerceRoute = () => {
  useTranslation();

  return (
    <WithEcommerceLayout
      WrappedComponent={
        <>
          <ScrollToTop />
          <Switch>
            <Suspense
              fallback={
                <div className="spin">
                  <Spin />
                </div>
              }
            >
              <Route exact path="/" component={Home} />
              <Route path="/product/:id" component={ProductForm} />
              <Route path="/invoice/:id" component={Invoice} />
              <Route path="/sellers" component={Sellers} />
              <Route path="/addresses" component={Address} />
              <Route path="/cart" component={ShoppingCart} />
              <Route path="/login/:source?" component={LoginCustomerView} />
              <Route path="/MyAccount" component={CustomerProfile} />
              <Route path="/validate/:token" component={ValidateToken} />
              <Route path="/payment-request/:id/:amount" component={PaymentLinkView} />
              <Route path="/payment-response" component={PaymentInvoiceView} />
              <Route path="/login-administrator" component={LoginAsAdminView} />
              <Route path={'/order-tracking/:id'} component={OrderTracking} />
              <Route path={'/approval/:jobId'} component={ApprovalView} />
            </Suspense>
          </Switch>
        </>
      }
    />
  );
};

export default EcommerceRoute;
