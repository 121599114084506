import actions from './actions';

const { GET_COMMERCIAL_GROUPS, GET_COMMERCIAL_GROUP_BY_ID, CLEAN_COMMERCIAL_GROUP, ERROR  } = actions;

const initialState = {
    commercialGroups: {},
    commercialGroup: {},

    error: null,
};

const commercialGroupReducer = (state = initialState, action) => { 
    const { type, data, err } = action;

    switch (type) {
        case GET_COMMERCIAL_GROUPS:
            return {
                ...state,
                commercialGroups: data.commercialGroups,
                loading: false,
            };
        case GET_COMMERCIAL_GROUP_BY_ID:
            return {
                ...state,
                commercialGroup: data.commercialGroup,
                loading: false,
            };
        case CLEAN_COMMERCIAL_GROUP:
            return {
                ...state,
                commercialGroup: {}
            };
        case ERROR:
            return {
                ...state,
                error: err
            };
        default:
            return state;
    }
};

export default commercialGroupReducer;
