import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { cleanResponseStatus } from '../../redux/ecommerce/actionCreator';
import { Modal } from '../../components/modals/antd-modals';
import { useTranslation } from 'react-i18next';

/* eslint-disable react-hooks/exhaustive-deps */
const AlertResponseEcommerce = ({ title = '' }) => {
  const dispatch = useDispatch();
  const [alert, setAlert] = useState(<></>);
  const responseStatus = useSelector(state => state.ecommerceReducer.responseStatus);
  const { t } = useTranslation();

  useEffect(() => {
    try {
      const alertBuild = error => {
        if (typeof error === 'string') {
          return (
            <li key={error} className="font-weight-normal">
              {error}
            </li>
          );
        } else if (Array.isArray(error)) {
          return error.map(data => alertBuild(data));
        } else if (typeof error === 'object') {
          return Object.keys(error).map(key => {
            return (
              <li key={key}>
                {key}
                <ul>{alertBuild(error[key])}</ul>
              </li>
            );
          });
        }
      };

      const closeAlert = () => {
        setAlert(<></>);
        dispatch(cleanResponseStatus());
      };

      if (Object.entries(responseStatus).length > 0) {
        switch (responseStatus.status) {
          case 'success':
            setAlert(
              <Modal title={title} visible={true} onOk={closeAlert} footer={[<></>]}>
                <p>{t('Saved information')}</p>
              </Modal>,
            );
            break;
          case 'error':
            const { message } = responseStatus.response.data;
            let build = [];
            if (typeof message === 'object') {
              Object.keys(message).forEach((key, i) => {
                build.push(
                  <div key={'div' + i}>
                    <div>{key}</div>
                    <ul className="mb-2">{alertBuild(message[key])}</ul>
                  </div>,
                );
              });
            }

            setAlert(
              <Modal title={title} visible={true} onCancel={closeAlert} footer={[<></>]}>
                {build.length > 0 ? (
                  <div className="text-custome">
                    <div className="mb-4">{t('The following fields have errors')}:</div>
                    {build.map(el => el)}
                  </div>
                ) : (
                  message || 'Status response ' + responseStatus.response.status
                )}
              </Modal>,
            );
            break;
          default:
        }
      }
    } catch (e) {
      console.warn(e);
    }
  }, [responseStatus, dispatch]);

  return <>{alert}</>;
};

export default AlertResponseEcommerce;
