import { combineReducers } from 'redux';
import themeUsersReducer from './themeUsers/reducers';
import authReducer from './authentication/reducers';
import ChangeLayoutMode from './themeLayout/reducers';
import orderReducer from './orders/reducers';
import kanbanBoardReducer from './kanban/reducers';
import Customers from './customers/reducers';
import Profile from './profile/reducers';
import Calender from './calendar/reducers';
import { axiosCrudReducer, axiosSingleCrudReducer } from './crud/axios/reducers';

import ecommerceReducer from './ecommerce/reducers';
import responseReducer from './responseAction/reducers';
import stockReducer from './stock/reducers';
import inventoryReducer from './inventory/reducers';
import itemReducer from './item/reducers';
import commercialGroupReducer from './commercialGroup/reducers';
import productReducerNew from './productReducer/reducers';
import resellersReducer from './reseller/reducers';
import taxReducer from './tax/reducers';
import bankReducer from './bank/reducers';
import permissionsReducer from './permissions/reducers';
import usersReducer from './users/reducers';
import mailTemplateReducer from './mailTemplate/reducers';
const rootReducers = combineReducers({
  themeUsers: themeUsersReducer,
  auth: authReducer,
  // email: emailReducer,
  // emailSingle: SingleEmailReducer,
  // products: productReducer,
  // product: SingleProductReducer,
  // projects: projectReducer,
  // project: SingleProjectReducer,
  ChangeLayoutMode,
  KanbanBoard: kanbanBoardReducer,
  Customers,
  Profile,
  Calender,
  AxiosCrud: axiosCrudReducer,
  SingleAxiosCrud: axiosSingleCrudReducer,
  ecommerceReducer: ecommerceReducer,
  orderReducer: orderReducer,
  itemReducer: itemReducer,
  productReducer: productReducerNew,
  inventoryReducer: inventoryReducer,
  commercialGroupReducer: commercialGroupReducer,
  stockReducer: stockReducer,
  resellersReducer: resellersReducer,
  responseReducer: responseReducer,
  taxReducer: taxReducer,
  bankReducer,
  permissionsReducer,
  usersReducer,
  mailTemplateReducer,
});

export default rootReducers;
