import actions from './actions';

const { 
    GET_ACTIVITY_TYPES, GET_ACTIVITY_TYPE_BY_ID, CLEAN_ACTIVITY_TYPE,
    GET_COMPANIES, GET_COMPANY_BY_ID, CLEAN_COMPANY,
    GET_CONTACT_TYPES, GET_CONTACT_TYPE_BY_ID, CLEAN_CONTACT_TYPE,
    GET_LANGUAGES, GET_LANGUAGE_BY_ID, CLEAN_LANGUAGE,
    GET_ADDRESS_BOOKS, GET_ADDRESS_BOOK_BY_ID, CLEAN_ADDRESS_BOOK,
    GET_USERS, GET_USER_BY_ID, CLEAN_USER,

    BEGIN, ERROR } = actions;

const initialState = {
    activityTypes: {},
    activityType: {},
    companies: {},
    company: {},
    contactTypes: {},
    contactType: {},
    languages: {},
    language: {},
    addressBooks: {},
    addressBook: {},
    users: {},
    user: {},

    loading: false,
    error: null,
};

const stockReducer = (state = initialState, action) => {
    const { type, data, err } = action;

    switch (type) {
        case GET_ACTIVITY_TYPES:
            return {
                ...state,
                activityTypes: data.activityTypes,
                loading: false,
            };
        case GET_ACTIVITY_TYPE_BY_ID:
            return {
                ...state,
                activityType: data.activityType,
                loading: false,
            };
        case CLEAN_ACTIVITY_TYPE:
            return {
                ...state,
                activityType: {}
            };
        case GET_COMPANIES:
            return {
                ...state,
                companies: data.companies,
                loading: false,
            };
        case GET_COMPANY_BY_ID:
            return {
                ...state,
                company: data.company,
                loading: false,
            };
        case CLEAN_COMPANY:
            return {
                ...state,
                company: {}
            };
        case GET_CONTACT_TYPES:
            return {
                ...state,
                contactTypes: data.contactTypes,
                loading: false,
            };
        case GET_CONTACT_TYPE_BY_ID:
            return {
                ...state,
                contactType: data.contactType,
                loading: false,
            };
        case CLEAN_CONTACT_TYPE:
            return {
                ...state,
                contactType: {}
            };
        case GET_LANGUAGES:
            return {
                ...state,
                languages: data.languages,
                loading: false,
            };
        case GET_LANGUAGE_BY_ID:
            return {
                ...state,
                language: data.language,
                loading: false,
            };
        case CLEAN_LANGUAGE:
            return {
                ...state,
                language: {}
            };
        case GET_ADDRESS_BOOKS:
            return {
                ...state,
                addressBooks: data.addressBooks,
                loading: false,
            };
        case GET_ADDRESS_BOOK_BY_ID:
            return {
                ...state,
                addressBook: data.addressBook,
                loading: false,
            };
        case CLEAN_ADDRESS_BOOK:
            return {
                ...state,
                addressBook: {}
            };
        case GET_USERS:
            return {
                ...state,
                users: data.users,
                loading: false,
            };
        case GET_USER_BY_ID:
            return {
                ...state,
                user: data.user,
                loading: false,
            };
        case CLEAN_USER:
            return {
                ...state,
                user: {}
            };
        case BEGIN:
            return {
                ...state,
                loading: true,
            };
        case ERROR:
            return {
                ...state,
                error: err,
                loading: false,
            };
        default:
            return state;
    }
};

export default stockReducer;
