const actions = {
  GET_PERMISSIONS: 'GET_PERMISSIONS',

  GET_ADMINS: 'GET_ADMINS',
  GET_ADMIN_BY_ID: 'GET_ADMIN_BY_ID',
  CLEAN_ADMIN: 'CLEAN_ADMIN',

  ACTION_BEGIN: 'ACTION_BEGIN',
  ERROR: 'ERROR',

  permissionsSuccess: data => {
    return { type: actions.GET_PERMISSIONS, data };
  },
  adminsSuccess: data => {
    return { type: actions.GET_ADMINS, data };
  },
  adminSuccess: data => {
    return { type: actions.GET_ADMIN_BY_ID, data };
  },
  cleanAdminAction: () => {
    return { type: actions.CLEAN_ADMIN };
  },

  actionBegin: data => {
    return { type: actions.ACTION_BEGIN, data };
  },
  error: err => {
    return { type: actions.ERROR, err };
  },
};

export default actions;
