import actions from './actions';

const {
  GET_WAREHOUSES,
  GET_WAREHOUSE_BY_ID,
  CLEAN_WAREHOUSE,
  GET_SUPPLIES,
  GET_SUPPLY_BY_ID,
  CLEAN_SUPPLY,
  GET_SUPPLIES_AVAILABLES,
  GET_PROVIDERS,
  GET_PROVIDER_BY_ID,
  CLEAN_PROVIDER,
  GET_INCIDENTS,
  GET_STOCKS,
  GET_PROVINCES,
  ERROR,
} = actions;

const initialState = {
  warehouses: [],
  warehouse: {},
  suppliesAvailables: {},
  supplies: {},
  supply: {},
  providers: [],
  provider: {},
  incidents: [],
  stocks: [],
  provinces: [],
  providersSelect: [],
  error: null,
};

const inventoryReducer = (state = initialState, action) => {
  const { type, data, err } = action;

  switch (type) {
    case GET_WAREHOUSES:
      return {
        ...state,
        warehouses: data.warehouses,
        loading: false,
      };
    case GET_WAREHOUSE_BY_ID:
      return {
        ...state,
        warehouse: data.warehouse,
        loading: false,
      };
    case CLEAN_WAREHOUSE:
      return {
        ...state,
        warehouse: {},
      };
    case GET_SUPPLIES:
      return {
        ...state,
        supplies: data.supplies,
        loading: false,
      };
    case GET_SUPPLY_BY_ID:
      return {
        ...state,
        supply: data.supply,
        loading: false,
      };
    case CLEAN_SUPPLY:
      return {
        ...state,
        supply: {},
      };
    case GET_SUPPLIES_AVAILABLES:
      return {
        ...state,
        suppliesAvailables: data.supplies,
      };
    case GET_PROVIDERS:
      return {
        ...state,
        providers: data.providers,
        providersSelect: data.providersSelect,
        loading: false,
      };
    case GET_PROVIDER_BY_ID:
      return {
        ...state,
        provider: data.provider,
        loading: false,
      };
    case CLEAN_PROVIDER:
      return {
        ...state,
        provider: {},
      };
    case GET_INCIDENTS:
      return {
        ...state,
        incidents: data.incidents,
        loading: false,
      };
    case GET_STOCKS:
      return {
        ...state,
        stocks: data.stocks,
        loading: false,
      };
    case GET_PROVINCES:
      return {
        ...state,
        provinces: data.provinces,
        loading: false,
      };
    case ERROR:
      return {
        ...state,
        error: err,
      };
    default:
      return state;
  }
};

export default inventoryReducer;
