const actions = {
  GET_ORDERS: 'GET_ORDERS',
  GET_ORDER_BY_ID: 'GET_ORDER_BY_ID',
  CLEAN_ORDER: 'CLEAN_ORDER',
  SET_PRODUCT: 'SET_PRODUCT',
  SET_PRODUCT_SCANNED: 'SET_PRODUCT_SCANNED',
  CLEAN_ORDERS: 'CLEAN_ORDERS',
  ACTION_BEGIN: 'ACTION_BEGIN',
  ERROR: 'ERROR',

  actionBegin: data => {
    return { type: actions.ACTION_BEGIN, data };
  },

  orderScanned: data => {
    return { type: actions.SET_PRODUCT_SCANNED, data };
  },
  ordersSuccess: data => {
    return { type: actions.GET_ORDERS, data };
  },
  orderSuccess: data => {
    return { type: actions.GET_ORDER_BY_ID, data };
  },
  cleanSuccessAction: () => {
    return { type: actions.CLEAN_ORDER };
  },
  cleanOrdersSuccessAction: () => {
    return { type: actions.CLEAN_ORDERS };
  },
  setSingleProduct: data => {
    return { type: actions.SET_PRODUCT, data };
  },
  error: err => {
    return { type: actions.ERROR, err };
  },
};

export default actions;
