import actions from './actions';

const { ERROR, ACTION_BEGIN, SUCCESS_MAILTEMPLATES } = actions;

const initialState = {
  templates: {},
  loading: false,
  error: null,
};

const ordersReducer = (state = initialState, action) => {
  const { type, data, err } = action;

  switch (type) {
    case SUCCESS_MAILTEMPLATES:
      return {
        ...state,
        templates: data,
      };
    case ACTION_BEGIN:
      const stateClone = { ...state, ...data };
      return {
        ...stateClone,
      };
    case ERROR:
      return {
        ...state,
        error: err,
        loading: false,
      };
    default:
      return state;
  }
};

export default ordersReducer;
