import React, { useEffect, useState } from 'react';
import { Menu } from 'antd';
import { NavLink, useRouteMatch, useLocation } from 'react-router-dom';
import FeatherIcon from 'feather-icons-react';
import propTypes from 'prop-types';
import Cookies from 'js-cookie';
import { useSelector } from 'react-redux';

const { SubMenu } = Menu;

/* eslint-disable react/jsx-no-target-blank */
const MenuItems = ({ darkMode, toggleCollapsed, topMenu }) => {
  const { path } = useRouteMatch();
  const location = useLocation();

  const permissions = useSelector(state => state.Profile.permissions);
  const pathName = window.location.pathname;
  const pathArray = pathName.split(path);
  const mainPath = pathArray[1];
  const mainPathSplit = mainPath.split('/');
  const [openKeys, setOpenKeys] = React.useState(
    !topMenu ? [`${mainPathSplit.length > 2 ? mainPathSplit[1] : 'dashboard'}`] : [],
  );

  const [user, setUser] = useState({});
  const [cpressToken, setcpressToken] = useState({});
  const [permissionsArray, setPermissionsArray] = useState([]);
  const cpressUrl = process.env.REACT_APP_CHEQUEPRESS_URL

  useEffect(() => {
    // Menu will be built based on user role

    if (Cookies.get('cf_user')) setUser(JSON.parse(Cookies.get('cf_user')));
    setcpressToken(Cookies.get('cf_internal_access_token'));
  }, []);

  useEffect(() => {
    if (permissions)
      setPermissionsArray(
        permissions
          .map(el => {
            return [
              { id: 1, name: 'orders' },
              { id: 2, name: 'commercial-groups' },
              { id: 3, name: 'resellers' },
              { id: 4, name: 'items' },
              { id: 5, name: 'products' },
              { id: 6, name: 'inventory' },
              { id: 7, name: 'customers' },
              { id: 8, name: 'tracking' },
              { id: 9, name: 'taxes' },
              { id: 10, name: 'permissions' },
              { id: 11, name: 'configuration' },
              { id: 12, name: 'printing' },
            ].find(p => p.name === el);
          })
          .sort((a, b) => {
            return a.id - b.id;
          }),
      );
  }, [permissions]);

  const onOpenChange = keys => {
    setOpenKeys(keys[keys.length - 1] !== 'recharts' ? [keys.length && keys[keys.length - 1]] : keys);
  };

  const onClick = item => {
    if (item.keyPath.length === 1) setOpenKeys([]);
  };

  const getComponents = () => {
    return permissionsArray.map(permission => {
      switch (permission?.name) {
        /**
         *
         * Admins
         */
        case 'orders':
          return (
            <Menu.Item
              icon={
                !topMenu && (
                  <NavLink className="menuItem-iocn" to={`${path}/orders/list`}>
                    <FeatherIcon icon="shopping-cart" />
                  </NavLink>
                )
              }
              key={`${path}/orders/list`}
            >
              <NavLink onClick={toggleCollapsed} to={`${path}/orders/list`}>
                Orders
              </NavLink>
            </Menu.Item>
          );
        case 'customers':
          return (
            <Menu.Item
              icon={
                !topMenu && (
                  <NavLink className="menuItem-iocn" to={`${path}/customers`}>
                    <FeatherIcon icon="users" />
                  </NavLink>
                )
              }
              key={`${path}/customers`}
            >
              <NavLink onClick={toggleCollapsed} to={`${path}/customers`}>
                Customers
              </NavLink>
            </Menu.Item>
          );
        case 'tracking':
          return (
            <Menu.Item
              icon={
                !topMenu && (
                  <NavLink className="menuItem-iocn" to={`${path}/tracking`}>
                    <FeatherIcon icon="box" />
                  </NavLink>
                )
              }
              key={`${path}/tracking`}
            >
              <NavLink onClick={toggleCollapsed} to={`${path}/tracking`}>
                Tracking
              </NavLink>
            </Menu.Item>
          );
        case 'resellers':
          return (
            <SubMenu key={`${path}/resellers-menu`} icon={!topMenu && <FeatherIcon icon="user" />} title="Resellers">
              <Menu.Item key={`${path}/resellers/list`}>
                <NavLink onClick={toggleCollapsed} to={`${path}/resellers/list`}>
                  Resellers
                </NavLink>
              </Menu.Item>
              <Menu.Item key={`${path}/resellers/balances-report`}>
                <NavLink onClick={toggleCollapsed} to={`${path}/resellers/balances-report`}>
                  Credit Balance
                </NavLink>
              </Menu.Item>
            </SubMenu>
          );
        case 'commercial-groups':
          return (
            <SubMenu key={`commercial_group`} icon={!topMenu && <FeatherIcon icon="users" />} title="Commercial Groups">
              <Menu.Item key={`${path}/commercial_group`}>
                <NavLink onClick={toggleCollapsed} to={`${path}/commercial_group`}>
                  Commercial Group
                </NavLink>
              </Menu.Item>
              <Menu.Item key={`${path}/commercial_group/commercial_group_users`}>
                <NavLink onClick={toggleCollapsed} to={`${path}/commercial_group/commercial_group_users`}>
                  Commercial Group Users
                </NavLink>
              </Menu.Item>
            </SubMenu>
          );
        case 'items':
          return (
            <SubMenu key={`item`} icon={!topMenu && <FeatherIcon icon="package" />} title="Items">
              <Menu.Item key={`${path}/item/item_type`}>
                <NavLink onClick={toggleCollapsed} to={`${path}/item/item_type`}>
                  Item types
                </NavLink>
              </Menu.Item>
              <Menu.Item key={`${path}/item/item_template`}>
                <NavLink onClick={toggleCollapsed} to={`${path}/item/item_template`}>
                  Item templates
                </NavLink>
              </Menu.Item>
              <Menu.Item key={`${path}/item/software_type`}>
                <NavLink onClick={toggleCollapsed} to={`${path}/item/software_type`}>
                  Item software
                </NavLink>
              </Menu.Item>
              <Menu.Item key={`${path}/item/item_forms`}>
                <NavLink onClick={toggleCollapsed} to={`${path}/item/item_forms`}>
                  Item data forms
                </NavLink>
              </Menu.Item>
              <Menu.Item key={`${path}/item`}>
                <NavLink onClick={toggleCollapsed} to={`${path}/item`}>
                  Items
                </NavLink>
              </Menu.Item>
              <Menu.Item key={`${path}/item/item_recipe`}>
                <NavLink onClick={toggleCollapsed} to={`${path}/item/item_recipe`}>
                  Item’s supplies
                </NavLink>
              </Menu.Item>
            </SubMenu>
          );
        case 'products':
          return (
            <SubMenu key={`product`} icon={!topMenu && <FeatherIcon icon="book" />} title="Products">
              <Menu.Item key={`${path}/product/category`}>
                <NavLink onClick={toggleCollapsed} to={`${path}/product/category`}>
                  Categories
                </NavLink>
              </Menu.Item>
              <Menu.Item key={`${path}/product`}>
                <NavLink onClick={toggleCollapsed} to={`${path}/product`}>
                  Products catalog
                </NavLink>
              </Menu.Item>
              <Menu.Item key={`${path}/product/product-recipe`}>
                <NavLink onClick={toggleCollapsed} to={`${path}/product/product-recipe`}>
                  Products configuration
                </NavLink>
              </Menu.Item>
              <Menu.Item key={`${path}/product/product_price`}>
                <NavLink onClick={toggleCollapsed} to={`${path}/product/product_price`}>
                  Products prices
                </NavLink>
              </Menu.Item>
              <Menu.Item key={`${path}/product/color`}>
                <NavLink onClick={toggleCollapsed} to={`${path}/product/color`}>
                  Products colors
                </NavLink>
              </Menu.Item>

              <Menu.Item key={`${path}/product/promo-codes`}>
                <NavLink onClick={toggleCollapsed} to={`${path}/product/promo-codes`}>
                  Promo codes
                </NavLink>
              </Menu.Item>
            </SubMenu>
          );
        case 'inventory':
          return (
            <SubMenu key={`${path}/inventory`} icon={!topMenu && <FeatherIcon icon="archive" />} title="Inventory">
              <Menu.Item key={`${path}/inventory/warehouse`}>
                <NavLink onClick={toggleCollapsed} to={`${path}/inventory/warehouse`}>
                  Warehouses
                </NavLink>
              </Menu.Item>
              <Menu.Item key={`${path}/inventory/supplies`}>
                <NavLink onClick={toggleCollapsed} to={`${path}/inventory/supplies`}>
                  Supplies catalog
                </NavLink>
              </Menu.Item>
              <Menu.Item key={`${path}/inventory/warehouse_stock`}>
                <NavLink onClick={toggleCollapsed} to={`${path}/inventory/warehouse_stock`}>
                  Warehouse stock
                </NavLink>
              </Menu.Item>
              {/*<Menu.Item key={`${path}/inventory/warehouse_transfer`}>
                <NavLink onClick={toggleCollapsed} to={`${path}/inventory/warehouse_transfer`}>
                  Warehouse transfers
                </NavLink>
          </Menu.Item>*/}
              <Menu.Item key={`${path}/inventory/incidents`}>
                <NavLink onClick={toggleCollapsed} to={`${path}/inventory/incidents`}>
                  Stock incidents
                </NavLink>
              </Menu.Item>
              <Menu.Item key={`${path}/inventory/providers`}>
                <NavLink onClick={toggleCollapsed} to={`${path}/inventory/providers`}>
                  Providers catalog
                </NavLink>
              </Menu.Item>
              <Menu.Item key={`${path}/inventory/orders`}>
                <NavLink onClick={toggleCollapsed} to={`${path}/inventory/orders`}>
                  Supplies order
                </NavLink>
              </Menu.Item>
              <Menu.Item key={`${path}/inventory/history`}>
                <NavLink onClick={toggleCollapsed} to={`${path}/inventory/history`}>
                  Inventory logs
                </NavLink>
              </Menu.Item>
            </SubMenu>
          );
        case 'taxes':
          return (
            <Menu.Item
              icon={
                !topMenu && (
                  <NavLink className="menuItem-iocn" to={`${path}/taxes`}>
                    <FeatherIcon icon="dollar-sign" />
                  </NavLink>
                )
              }
              key={`${path}/taxes`}
            >
              <NavLink onClick={toggleCollapsed} to={`${path}/taxes`}>
                Taxes
              </NavLink>
            </Menu.Item>
          );
        case 'permissions':
          return (
            <SubMenu key={`permissions`} icon={!topMenu && <FeatherIcon icon="lock" />} title="Permissions">
              <Menu.Item key={`${path}/permissions/users`}>
                <NavLink onClick={toggleCollapsed} to={`${path}/permissions/users`}>
                  Internal User
                </NavLink>
              </Menu.Item>
              <Menu.Item key={`${path}/permissions`}>
                <NavLink onClick={toggleCollapsed} to={`${path}/permissions`}>
                  Permissions
                </NavLink>
              </Menu.Item>
            </SubMenu>
          );
        case 'configuration':
          return (
            <SubMenu key={`${path}/conf`} icon={!topMenu && <FeatherIcon icon="package" />} title="Configuration">
              <Menu.Item key={`${path}/conf/email-templates`}>
                <NavLink onClick={toggleCollapsed} to={`${path}/conf/email-templates`}>
                  Email templates
                </NavLink>
              </Menu.Item>
              <Menu.Item key={`${path}/conf/banks`}>
                <NavLink onClick={toggleCollapsed} to={`${path}/conf/banks`}>
                  Banks
                </NavLink>
              </Menu.Item>
            </SubMenu>
          );
        case 'printing':
          return (
            <Menu.Item
              icon={
                !topMenu && (
                  <a
                    className="menuItem-iocn"
                    href={`${cpressUrl}/auth?token=${cpressToken}`}
                    target="_blank"
                  >
                    <FeatherIcon icon="printer" />
                  </a>
                )
              }
              key="link_to_cpress"
            >
              <a href={`${cpressUrl}/auth?token=${cpressToken}`} target="_blank">
                Chequespress
              </a>
            </Menu.Item>
          );

        /**
         *
         * Resellers
         */
        default:
          return <></>;
      }
    });
  };

  return (
    <Menu
      onOpenChange={onOpenChange}
      onClick={onClick}
      mode={!topMenu || window.innerWidth <= 991 ? 'inline' : 'horizontal'}
      theme={darkMode && 'dark'}
      selectedKeys={[location.pathname]}
      overflowedIndicator={<FeatherIcon icon="more-vertical" />}
      openKeys={openKeys}
    >
      {user.role_id === 2 ? (
        <>
          <Menu.Item
            icon={
              !topMenu && (
                <NavLink className="menuItem-iocn" to={`${path}/orders/list`}>
                  <FeatherIcon icon="package" />
                </NavLink>
              )
            }
            key={`${path}/orders/list`}
          >
            <NavLink onClick={toggleCollapsed} to={`${path}/orders/list`}>
              Orders
            </NavLink>
          </Menu.Item>

          <Menu.Item
            icon={
              !topMenu && (
                <NavLink className="menuItem-iocn" to={`${path}/customers`}>
                  <FeatherIcon icon="users" />
                </NavLink>
              )
            }
            key={`${path}/customers`}
          >
            <NavLink onClick={toggleCollapsed} to={`${path}/customers`}>
              Customers
            </NavLink>
          </Menu.Item>

          <Menu.Item
            icon={
              !topMenu && (
                <NavLink className="menuItem-iocn" to={`${path}/profile/myProfile/website-settings`}>
                  <FeatherIcon icon="globe" />
                </NavLink>
              )
            }
            key={`${path}/profile/myProfile/website-settings`}
          >
            <NavLink onClick={toggleCollapsed} to={`${path}/profile/myProfile/website-settings`}>
              Website settings
            </NavLink>
          </Menu.Item>

          <Menu.Item
            icon={
              !topMenu && (
                <NavLink className="menuItem-iocn" to={`${path}/balance`}>
                  <FeatherIcon icon="dollar-sign" />
                </NavLink>
              )
            }
            key={`${path}/balance`}
          >
            <NavLink onClick={toggleCollapsed} to={`${path}/balance`}>
              Credit Balance
            </NavLink>
          </Menu.Item>
        </>
      ) : (
        <>{getComponents()}</>
      )}
    </Menu>
  );
};

MenuItems.propTypes = {
  darkMode: propTypes.bool,
  topMenu: propTypes.bool,
  toggleCollapsed: propTypes.func,
};

export default MenuItems;
