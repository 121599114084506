import actions from './actions';

const initialState = {
  customersList: [],
  customersLinks: {},
  customersMeta: {},
  loading: false,
  error: null,
  customerDetail: {},

  loadingCustomers: false,
  loadingBanking: false,
  loadingCompanyBook: false,
  loadingBankBook: false,
  loadingAddress: false,
  loadingOrders: false,
};

const {
  ACTION_BEGIN,

  CUSTOMERS_LOAD_BEGIN,
  CUSTOMERS_LOAD_SUCCESS,
  CUSTOMERS_LOAD_ERR,

  CUSTOMER_DETAIL_LOAD_BEGIN,
  CUSTOMER_DETAIL_LOAD_SUCCESS,
  CUSTOMER_DETAIL_LOAD_ERR,
} = actions;

const CustomersReducer = (state = initialState, action) => {
  const { type, data, err } = action;
  switch (type) {
    case ACTION_BEGIN:
      const stateClone = { ...state, ...data };
      return {
        ...stateClone,
      };
    case CUSTOMERS_LOAD_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case CUSTOMERS_LOAD_SUCCESS:
      return {
        ...state,
        customersList: data.data,
        customersLinks: data.links,
        customersMeta: data.meta,
        loading: false,
      };
    case CUSTOMERS_LOAD_ERR:
      return {
        ...state,
        error: err,
        loading: false,
        loadingCustomers: false,
      };

    case CUSTOMER_DETAIL_LOAD_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case CUSTOMER_DETAIL_LOAD_SUCCESS:
      return {
        ...state,
        customerDetail: data.data,
        loading: false,
      };
    case CUSTOMER_DETAIL_LOAD_ERR:
      return {
        ...state,
        error: err,
        loading: false,
        loadingCustomers: false,
      };

    default:
      return state;
  }
};

export default CustomersReducer;
