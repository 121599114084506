import actions from './actions';

const {
  GET_ORDERS,
  GET_ORDER_BY_ID,
  CLEAN_ORDER,
  SET_PRODUCT,
  ERROR,
  SET_PRODUCT_SCANNED,
  CLEAN_ORDERS,
  ACTION_BEGIN,
} = actions;

const initialState = {
  orders: {},
  order: {},
  product: {},
  loadingOrders: false,
  loadingOrder: false,
  loadingCoincidences: false,
  loadingCustomers: false,
  productsScanned: null,
  error: null,
};

const ordersReducer = (state = initialState, action) => {
  const { type, data, err } = action;

  switch (type) {
    case ACTION_BEGIN:
      const stateClone = { ...state, ...data };
      return {
        ...stateClone,
      };

    case SET_PRODUCT_SCANNED:
      return {
        ...state,
        productsScanned: data.productsScanned,
      };
    case GET_ORDERS:
      return {
        ...state,
        orders: data.orders,
        loading: false,
      };
    case GET_ORDER_BY_ID:
      return {
        ...state,
        order: data.order,
        loading: false,
      };
    case CLEAN_ORDER:
      return {
        ...state,
        order: {},
      };
    case CLEAN_ORDERS:
      return {
        ...state,
        orders: [],
      };
    case SET_PRODUCT:
      return { ...state, product: data };
    case ERROR:
      return {
        ...state,
        error: err,
        loading: false,
        loadingOrders: false,
        loadingCoincidences: false,
        loadingOrder: false,
        loadingCustomers: false,
      };
    default:
      return state;
  }
};

export default ordersReducer;
