import React, { lazy, Suspense, useEffect, useState } from "react";
import { Spin } from "antd";
import {
  Switch,
  Route,
  useRouteMatch,
  useLocation,
  useHistory,
} from "react-router-dom";
import Orders from "./orders";
import withAdminLayout from "../../layout/withAdminLayout";
import Cookies from "js-cookie";
import { getPermissions } from "../../redux/profile/actionCreator";
import { useDispatch, useSelector } from "react-redux";

const CustomersList = lazy(() =>
  import("../../container/customers/CustomersList")
);
const Myprofile = lazy(() => import("../../container/profile/myProfile/Index"));
const Customers = lazy(() => import("../../container/customers/Customers"));
const Stock = lazy(() => import("./stock"));
const Item = lazy(() => import("./item"));
const Inventory = lazy(() => import("./inventory"));
const Product = lazy(() => import("./product"));
const CommercialGroup = lazy(() => import("./commercialGroup"));
const Resellers = lazy(() => import("./reseller"));
const Taxes = lazy(() => import("./taxes"));
const Tracking = lazy(() => import("../../container/tracking/Tracking"));
const Permissions = lazy(() => import("./permissions"));
const CustomersBalances = lazy(() =>
  import("../../container/customers/overview/CustomersBalances")
);

const ExternalAuthAppView = lazy(() =>
  import("../../container/permissions/ExternalAuthApp")
);
const OrderTracking = lazy(() =>
  import("../../container/tracking/order/OrderTracking")
);

/* eslint-disable react-hooks/exhaustive-deps */
const Admin = () => {
  const dispatch = useDispatch();
  const permissions = useSelector((state) => state.Profile.permissions);
  const { path } = useRouteMatch();
  const { pathname } = useLocation();
  const history = useHistory();

  const [user, setUser] = useState({});
  const [routes, setRoutes] = useState([]);
  const [pathAdmin, setPathAdmin] = useState("");
  const [permissionsArray, setPermissionsArray] = useState([]);

  useEffect(() => {
    if (Cookies.get("cf_user")) setUser(JSON.parse(Cookies.get("cf_user")));
  }, []);

  useEffect(() => {
    if (permissionsArray) {
      setRoutes(getComponents());
    }
  }, [permissionsArray]);

  useEffect(() => {
    if (routes.length > 0) setPathAdmin(routes[0]?.props?.path);
  }, [routes]);

  useEffect(() => {
    if (user?.id) dispatch(getPermissions(user?.id));
  }, [user]);

  useEffect(() => {
    if (pathname === "/admin") {
      if (user.role_id === 2) {
        history.push(`${path}/orders/list`);
      }

      if (user.role_id === 1) {
        history.push(pathAdmin);
      }
    }
  }, [pathname, pathAdmin]);

  useEffect(() => {
    if (permissions)
      setPermissionsArray(
        permissions
          .map((el) => {
            return [
              { id: 1, name: "orders" },
              { id: 2, name: "commercial-groups" },
              { id: 3, name: "resellers" },
              { id: 4, name: "items" },
              { id: 5, name: "products" },
              { id: 6, name: "inventory" },
              { id: 7, name: "customers" },
              { id: 8, name: "tracking" },
              { id: 9, name: "taxes" },
              { id: 10, name: "permissions" },
              { id: 11, name: "configuration" },
              { id: 12, name: "printing" },
            ].find((p) => p.name === el);
          })
          .sort((a, b) => {
            return a.id - b.id;
          })
      );
  }, [permissions]);

  const getComponents = () => {
    return permissionsArray.map((permission) => {
      switch (permission?.name) {
        /**
         *
         * Admins
         */
        case "commercial-groups":
          return (
            <Route
              path={`${path}/commercial_group`}
              component={CommercialGroup}
            />
          );
        case "configuration":
          return <Route path={`${path}/conf`} component={Stock} />;
        case "inventory":
          return <Route path={`${path}/inventory`} component={Inventory} />;
        case "items":
          return <Route path={`${path}/item`} component={Item} />;
        case "orders":
          return <Route path={`${path}/orders`} component={Orders} />;
        case "products":
          return <Route path={`${path}/product`} component={Product} />;
        case "resellers":
          return <Route path={`${path}/resellers`} component={Resellers} />;
        case "taxes":
          return <Route path={`${path}/taxes`} component={Taxes} />;
        case "tracking":
          return <Route path={`${path}/tracking`} component={Tracking} />;
        case "permissions":
          return <Route path={`${path}/permissions`} component={Permissions} />;
        case "customers":
          return <Route path={`${path}/customers`} component={CustomersList} />;
        /**
         *
         * Resellers
         */
        default:
          return <></>;
      }
    });
  };

  return (
    <Switch>
      <Suspense
        fallback={
          <div className="spin">
            <Spin />
          </div>
        }
      >
        {user.role_id === 2 ? (
          <>
            <Route path={`${path}/orders`} component={Orders} />
            <Route path={`${path}/customers`} component={Customers} />
            <Route path={`${path}/profile/myProfile`} component={Myprofile} />
            <Route path={`${path}/balance`} component={CustomersBalances} />
          </>
        ) : (
          <>
            {routes}
            <Route path={`${path}/authapp`} component={ExternalAuthAppView} />
            <Route
              path={`${path}/order-tracking/:id`}
              component={OrderTracking}
            />
          </>
        )}
      </Suspense>
    </Switch>
  );
};

export default withAdminLayout(Admin);
// export default Admin;
