import React, { lazy } from 'react';
import { Route, useRouteMatch, Switch, Redirect } from 'react-router-dom';

const OrdersList = lazy(() => import('../../container/orders/Orders'));
const Detail = lazy(() => import('../../container/orders/Detail'));
const AddManualOrderView = lazy(() => import('../../container/orders/AddManualOrder'));
const CancelOrderView = lazy(() => import('../../container/orders/CancelOrder'));

const OrdersRoute = () => {
  const { path } = useRouteMatch();
  return (
    <Switch>
      <Route path={`${path}/list/:statusParam?`} component={OrdersList} />
      <Route path={`${path}/detail/:id`} component={Detail} />
      <Route path={`${path}/add-manual`} component={AddManualOrderView} />
      <Route path={`${path}/cancel/:id`} component={CancelOrderView} />
      <Redirect from="/" to={`${path}/list`} />
    </Switch>
  );
};

export default OrdersRoute;
