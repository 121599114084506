import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import en_CA from './config/lang/en_CA';
import en from './config/lang/en';
import fr from './config/lang/fr';
const options = {
  // order and from where user language should be detected
  order: ['sessionStorage', 'navigator', 'htmlTag'],

  // cache user language on
  caches: ['sessionStorage'],
  excludeCacheFor: ['cimode'], // languages to not persist (cookie, localStorage)

  // keys or params to lookup language from
  lookupQuerystring: 'lng',
  lookupCookie: 'i18next',
  lookupLocalStorage: 'i18nextLng',
  lookupSessionStorage: 'i18nextLng',
  lookupFromPathIndex: 0,
  lookupFromSubdomainIndex: 0,
};

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    // init resources
    resources: {
      en,
      'en-CA': en_CA,
      'fr-CA': fr,
    },
    fallbackLng: 'en-CA',

    detection: options,
    nsSeparator: ':::',
    keySeparator: '::',

    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
