import actions from './actions';

const { GET_TAXES, GET_TAX_BY_ID, CLEAN_TAX, ERROR, GET_STATE_TAXES } = actions;

const initialState = {
  taxes: {},
  tax: {},
  states: {},

  error: null,
};

const taxReducer = (state = initialState, action) => {
  const { type, data, err } = action;

  switch (type) {
    case GET_TAXES:
      return {
        ...state,
        taxes: data.taxes,
        loading: false,
      };
    case GET_TAX_BY_ID:
      return {
        ...state,
        tax: data.tax,
        loading: false,
      };
    case CLEAN_TAX:
      return {
        ...state,
        tax: {},
      };
    case ERROR:
      return {
        ...state,
        error: err,
      };
    case GET_STATE_TAXES:
      return {
        ...state,
        states: data.states,
        loading: false,
      };

    default:
      return state;
  }
};

export default taxReducer;
