const actions = {
  GET_TAXES: 'GET_TAXES',
  GET_TAX_BY_ID: 'GET_TAX_BY_ID',
  CLEAN_TAX: 'CLEAN_TAX',
  ERROR: 'ERROR',
  GET_STATE_TAXES: 'GET_STATE_TAXES',

  taxesSuccess: data => {
    return { type: actions.GET_TAXES, data };
  },
  taxSuccess: data => {
    return { type: actions.GET_TAX_BY_ID, data };
  },
  cleanTaxAction: () => {
    return { type: actions.CLEAN_TAX };
  },

  error: err => {
    return { type: actions.ERROR, err };
  },
  stateSuccess: data => {
    return { type: actions.GET_STATE_TAXES, data };
  },
};

export default actions;
