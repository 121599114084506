import axios from 'axios';
import { setResponseStatus, cleanResponseStatus } from '../../redux/ecommerce/actionCreator';
import store from '../../redux/store';
import { getItem } from '../../utility/localStorageControl';

const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
const CPRESS_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
const { dispatch } = store;

const client = axios.create({
  baseURL: API_ENDPOINT,
  headers: {
    'Content-Type': 'application/json',
  },
  withCredentials: true,
});

const clientCpress = axios.create({
  baseURL: CPRESS_ENDPOINT,
  headers: {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${getItem('access_token')}`,
  },
  withCredentials: true,
});

const authHeader = () => ({
  Authorization: `Bearer ${sessionStorage.getItem('reseller')}`,
});

const authHeaderCustomer = () => ({
  Authorization: `Bearer ${localStorage.getItem('cf_customer')}`,
});

const authHeaderCpress = () => ({
  accept: 'application/json',
  'X-CSRF-TOKEN': '',
  Authorization: `Bearer ${getItem('access_token')}`,
});

class DataService {
  static get(path = '') {
    return client({
      method: 'GET',
      url: path,
      headers: { ...authHeader() },
    });
  }

  static post(path = '', data = {}) {
    return client({
      method: 'POST',
      url: path,
      data,
      headers: { ...authHeader() },
    });
  }

  static patch(path = '', data = {}) {
    return client({
      method: 'PATCH',
      url: path,
      data: JSON.stringify(data),
      headers: { ...authHeader() },
    });
  }

  static delete(path = '', data = {}) {
    return client({
      method: 'DELETE',
      url: path,
      data: JSON.stringify(data),
      headers: { ...authHeader() },
    });
  }

  static put(path = '', data = {}) {
    return client({
      method: 'PUT',
      url: path,
      data: JSON.stringify(data),
      headers: { ...authHeader() },
    });
  }

  // API calls as customer
  static getAsCustomer(path = '') {
    return client({
      method: 'GET',
      url: path,
      headers: { ...authHeaderCustomer() },
    });
  }

  static postAsCustomer(path = '', data = {}) {
    return client({
      method: 'POST',
      url: path,
      data,
      headers: { ...authHeaderCustomer() },
    });
  }

  static putAsCustomer(path = '', data = {}) {
    return client({
      method: 'PUT',
      url: path,
      data: JSON.stringify(data),
      headers: { ...authHeaderCustomer() },
    });
  }

  // Methods for consuming the CPRESS API
  static getCpress(path = '') {
    return clientCpress({
      method: 'GET',
      url: path,
      headers: { ...authHeaderCpress() },
    });
  }

  static postCpress(path = '', data = {}, optionalHeader = {}) {
    return clientCpress({
      method: 'POST',
      url: path,
      data,
      headers: { ...authHeaderCpress(), ...optionalHeader },
    });
  }

  static deleteCpress(path = '', data = {}) {
    return clientCpress({
      method: 'DELETE',
      url: path,
      data: JSON.stringify(data),
      headers: { ...authHeaderCpress() },
    });
  }

  static putCpress(path = '', data = {}) {
    return clientCpress({
      method: 'PUT',
      url: path,
      data: JSON.stringify(data),
      headers: { ...authHeaderCpress() },
    });
  }
}

client.interceptors.request.use(config => {
  const requestConfig = config;

  return requestConfig;
});

client.interceptors.response.use(
  response => {
    return response;
  },
  error => {
    const { response } = error;
    const originalRequest = error.config;

    if (response) {
      dispatch(setResponseStatus('error', response));

      if (response.status === 500) {
        // do something here
      } else {
        return originalRequest;
      }
    } else {
      dispatch(cleanResponseStatus());
    }
    return Promise.reject(error);
  },
);

// Interceptor for the cpress connection
clientCpress.interceptors.request.use(config => {
  const requestConfig = config;

  return requestConfig;
});

clientCpress.interceptors.response.use(
  response => {
    return response;
  },
  error => {
    const { response } = error;
    const originalRequest = error.config;

    if (response) {
      if (response.status === 500) {
        // do something here
      } else {
        return originalRequest;
      }
    }
    return Promise.reject(error);
  },
);

export { DataService };
