import actions from './actions';
//import initialState from '../../demoData/friends.json';
import { DataService } from '../../config/dataService/dataService';

const generic500Error = 'Internal Server Error';

const {
  permissionsSuccess,
  postDataBegin,
  postDataSuccess,
  postDataErr,
  readProfileSuccess,
  actionBegin,
  error,
} = actions;

const getPermissions = id => {
  return async dispatch => {
    try {
      const response = await DataService.get('/api/permissions/' + id);
      if (response.status === 200) {
        dispatch(permissionsSuccess(response.data.data?.permissions));
      }
    } catch (err) {}
  };
};

const readProfile = () => {
  return async dispatch => {
    try {
      dispatch(actionBegin({ loadingUpdate: true }));
      const response = await DataService.get('/api/users/user_details');
      if (response.status === 200) {
        dispatch(readProfileSuccess(response.data.data));
        dispatch(actionBegin({ loadingUpdate: false }));
      }
    } catch (err) {
      dispatch(error(err.toString()));
      alert(generic500Error);
    }
  };
};

const updateProfile = data => {
  return async dispatch => {
    try {
      dispatch(actionBegin({ loadingUpdate: true }));
      const response = await DataService.put('/api/users/user_details', data);

      if (response.status === 200) {
        dispatch(actionBegin({ loadingUpdate: false }));
        return response;
      }
    } catch (err) {
      dispatch(error(err.toString()));
      alert(generic500Error);
    }
  };
};

const submitPost = data => {
  return async dispatch => {
    try {
      dispatch(postDataBegin());
      dispatch(postDataSuccess(data));
    } catch (err) {
      dispatch(postDataErr(err));
    }
  };
};

const likeUpdate = (data, key) => {
  return async dispatch => {
    try {
      dispatch(postDataBegin());
      data.map(post => {
        if (post.postId === key) {
          return (post.like += 1);
        }
        return dispatch(postDataSuccess(data));
      });
    } catch (err) {
      dispatch(postDataErr(err));
    }
  };
};

const commentUpdate = (data, key, comment) => {
  return async dispatch => {
    try {
      dispatch(postDataBegin());
      data.map(post => {
        if (post.postId === key) {
          return (post.comment = [
            ...post.comment,
            {
              time: new Date().getTime(),
              from: 'David Warner',
              text: comment,
            },
          ]);
        }
        return dispatch(postDataSuccess(data));
      });
    } catch (err) {
      dispatch(postDataErr(err));
    }
  };
};

const postDelete = (data, key) => {
  return async dispatch => {
    try {
      dispatch(postDataBegin());
      const posts = data.filter(post => {
        return post.postId !== key;
      });
      return dispatch(postDataSuccess(posts));
    } catch (err) {
      dispatch(postDataErr(err));
    }
  };
};

export { getPermissions, submitPost, likeUpdate, commentUpdate, postDelete, readProfile, updateProfile };
