const actions = {
  ACTION_BEGIN: 'ACTION_BEGIN',

  CUSTOMERS_LOAD_BEGIN: 'CUSTOMERS_LOAD_BEGIN',
  CUSTOMERS_LOAD_SUCCESS: 'CUSTOMERS_LOAD_SUCCESS',
  CUSTOMERS_LOAD_ERR: 'CUSTOMERS_LOAD_ERR',

  CUSTOMER_DETAIL_LOAD_BEGIN: 'CUSTOMER_DETAIL_LOAD_BEGIN',
  CUSTOMER_DETAIL_LOAD_SUCCESS: 'CUSTOMER_DETAIL_LOAD_SUCCESS',
  CUSTOMER_DETAIL_LOAD_ERR: 'CUSTOMER_DETAIL_LOAD_ERR',

  CONTACT_STAR_UPDATE_BEGIN: 'CONTACT_STAR_UPDATE_BEGIN',
  CONTACT_STAR_UPDATE_SUCCESS: 'CONTACT_STAR_UPDATE_SUCCESS',
  CONTACT_STAR_UPDATE_ERR: 'CONTACT_STAR_UPDATE_ERR',

  actionBegin: data => {
    return { type: actions.ACTION_BEGIN, data };
  },

  customersLoadBegin: () => {
    return {
      type: actions.CUSTOMERS_LOAD_BEGIN,
    };
  },

  customersLoadSuccess: data => {
    return {
      type: actions.CUSTOMERS_LOAD_SUCCESS,
      data,
    };
  },

  customersLoadErr: err => {
    return {
      type: actions.CUSTOMERS_LOAD_ERR,
      err,
    };
  },

  customerDetailLoadBegin: () => {
    return {
      type: actions.CUSTOMER_DETAIL_LOAD_BEGIN,
    };
  },

  customerDetailLoadSuccess: data => {
    return {
      type: actions.CUSTOMER_DETAIL_LOAD_SUCCESS,
      data,
    };
  },

  customerDetailLoadErr: err => {
    return {
      type: actions.CUSTOMER_DETAIL_LOAD_ERR,
      err,
    };
  },

  readBegin: () => {
    return {
      type: actions.CONTACT_READ_BEGIN,
    };
  },

  readSuccess: data => {
    return {
      type: actions.CONTACT_READ_SUCCESS,
      data,
    };
  },

  readErr: err => {
    return {
      type: actions.CONTACT_READ_ERR,
      err,
    };
  },
};

export default actions;
