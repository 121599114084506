const actions = {
  GET_WAREHOUSES: 'GET_WAREHOUSES',
  GET_WAREHOUSE_BY_ID: 'GET_WAREHOUSE_BY_ID',
  CLEAN_WAREHOUSE: 'CLEAN_WAREHOUSE',

  GET_SUPPLIES: 'GET_SUPPLIES',
  GET_SUPPLY_BY_ID: 'GET_SUPPLY_BY_ID',
  CLEAN_SUPPLY: 'CLEAN_SUPPLY',
  GET_SUPPLIES_AVAILABLES: 'GET_SUPPLIES_AVAILABLES',

  GET_PROVIDERS: 'GET_PROVIDERS',
  GET_PROVIDER_BY_ID: 'GET_PROVIDER_BY_ID',
  CLEAN_PROVIDER: 'CLEAN_PROVIDER',

  GET_INCIDENTS: 'GET_INCIDENTS',
  GET_STOCKS: 'GET_STOCKS',
  GET_PROVINCES: 'GET_PROVINCES',

  ERROR: 'ERROR',

  warehousesSuccess: data => {
    return { type: actions.GET_WAREHOUSES, data };
  },
  warehouseSuccess: data => {
    return { type: actions.GET_WAREHOUSE_BY_ID, data };
  },
  cleanWarehouseAction: () => {
    return { type: actions.CLEAN_WAREHOUSE };
  },

  suppliesSuccess: data => {
    return { type: actions.GET_SUPPLIES, data };
  },
  supplySuccess: data => {
    return { type: actions.GET_SUPPLY_BY_ID, data };
  },
  cleanSupplyAction: () => {
    return { type: actions.CLEAN_SUPPLY };
  },
  suppliesAvailablesSuccess: data => {
    return { type: actions.GET_SUPPLIES_AVAILABLES, data };
  },
  providersSuccess: data => {
    return { type: actions.GET_PROVIDERS, data };
  },
  providerSuccess: data => {
    return { type: actions.GET_PROVIDER_BY_ID, data };
  },
  cleanProviderSuccess: () => {
    return { type: actions.CLEAN_PROVIDER };
  },
  incidentsSuccess: data => {
    return { type: actions.GET_INCIDENTS, data };
  },
  stocksSuccess: data => {
    return { type: actions.GET_STOCKS, data };
  },
  provincesSuccess: data => {
    return { type: actions.GET_PROVINCES, data };
  },
  error: err => {
    return { type: actions.ERROR, err };
  },
};

export default actions;
