/* eslint-disable no-shadow */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Layout, Menu, Button, Row, Col, Spin, Badge, Input, Form, Tooltip } from 'antd';
import { Link, useHistory } from 'react-router-dom';
import {
  GooglePlusOutlined,
  YoutubeOutlined,
  FacebookOutlined,
  TwitterOutlined,
  LinkedinOutlined,
  PhoneFilled,
  PushpinOutlined,
  ShoppingCartOutlined,
  UserOutlined,
  MailFilled,
  LogoutOutlined,
} from '@ant-design/icons';
import _ from 'lodash';
import {
  changeLanguageCustomer,
  cleanProfileCustomer,
  getOrderHistory,
  readProfile,
  readProfileCustomer,
  registerUserByCode,
  signInReseller,
} from '../redux/ecommerce/actionCreator';
import { DivEcommerce } from './style';
import { useTranslation } from 'react-i18next';
import i18n from '../i18n';
import AlertResponse from '../container/alertResponse/AlertResponseEcommerce';
import { CURRENCY_TYPES } from '../utility/constants';
import { useIdleTimer } from 'react-idle-timer';

const { Footer } = Layout;
let interval;

/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-useless-escape */
const WithEcommerceLayout = ({ WrappedComponent }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const history = useHistory();
  const orderData = useSelector(state => {
    if (sessionStorage.getItem('cf_orderIdEditByAdmin')) {
      return state.ecommerceReducer.customerOrder;
    } else return _.find(state.ecommerceReducer.customerOrders, { status: 1 });
  });

  const RESELLER = {
    id: 1,
    logo: '',
    contactPhone: [],
    contactEmail: [''],
    address: '',
    socialNetworks: [],
    topbar_color: 'white',
    footerbar_color: '#333333',
    titlePage: '',
    faviconUrl: '',
  };

  const loadingUser = useSelector(state => state.ecommerceReducer.loadingUser);
  const isSignedIn = useSelector(state => state.ecommerceReducer.isSignedIn);
  const user_detail = useSelector(state => {
    const { topbar_color, footerbar_color, titlePage, logo, faviconUrl } = RESELLER;
    let data = {};

    if (state.ecommerceReducer.userSettings) {
      data = state.ecommerceReducer.userSettings;
      if (!data.logo) data.logo = logo;

      if (!data.topbarColor) data.topbarColor = topbar_color;

      if (!data.footerbarColor) data.footerbarColor = footerbar_color;

      if (!data.titlePage) data.titlePage = titlePage;

      if (!data.faviconUrl) data.faviconUrl = faviconUrl;
    }

    return data;
  });
  const [state, setState] = useState({ current: '' });
  const [isMobile, setIsMobile] = useState(false);
  const [customerToken, setCustomerToken] = useState(localStorage.getItem('cf_customer'));
  const customer = useSelector(state => state.ecommerceReducer.customer);
  const handleClick = e => setState({ ...state, current: e });
  const changeLanguage = lng => {
    if (localStorage.getItem('cf_customer')) {
      dispatch(changeLanguageCustomer(lng === 'fr-CA' ? 'fr' : 'en'));
    }

    i18n.changeLanguage(lng);
  };

  useIdleTimer({
    onIdle: () => {
      if (customerToken) {
        logout();
      }
    },
    onActive: () => {},
    onMessage: () => {},
    timeout: 60000 * 15, // 15 minute
    crossTab: true,
    leaderElection: true,
    syncTimers: 200,
  });

  useEffect(() => {
    if (customerToken) {
      dispatch(changeLanguageCustomer(i18n.language === 'fr-CA' ? 'fr' : 'en'));

      dispatch(getOrderHistory());
    }

    if (customerToken) {
      dispatch(readProfileCustomer());
    }

    return () => {
      clearInterval(interval);
    };
  }, [customerToken]);

  useEffect(() => {
    setCustomerToken(localStorage.getItem('cf_customer'));
  }, [customer]);

  useEffect(async () => {
    let timeout;
    resizeCodeForm();
    if (!isSignedIn) {
      const subdomain = window.location.origin;
      const params = { subdomain };
      if (subdomain === 'http://localhost:3000') params.email = 'daniel+reseller@propulsoft.ca';

      const response = await dispatch(signInReseller(params));
      
      /*if (!response) {
        history.push('/404');
        window.location.reload();
      }*/
    } else {
      dispatch(readProfile());
    }

    const resize = () => {
      clearTimeout(timeout);

      timeout = setTimeout(() => {
        resizeCodeForm();
      }, 500);
    };

    document.getElementById('root').style.height = '100%';
    window.addEventListener('resize', resize);

    return () => {
      clearTimeout(timeout);
      document.getElementById('root').style.height = 'initial';
      window.removeEventListener('resize', resize);
    };
  }, [dispatch]);

  useEffect(() => {
    if (user_detail.titlePage) document.getElementById('pageTitle').innerText = user_detail.titlePage;
    if (user_detail.faviconUrl) document.getElementById('pageFavicon').href = user_detail.faviconUrl;
    if (user_detail.currency_type === CURRENCY_TYPES.USD) changeLanguage('en');
    if (user_detail.currency_type === CURRENCY_TYPES.CAD && i18n.language !== 'fr-CA') changeLanguage('en-CA');

    if (user_detail.google_ads) {
      user_detail.google_ads.forEach(el => {
        if (el.type === 'head') {
          if (!el.script.includes('<script')) {
            const existingScript = document.getElementById(el.name);
            if (existingScript) document.head.removeChild(existingScript);

            const script = document.createElement('script');
            script.id = el.name;
            script.type = 'text/javascript';
            script.innerHTML = el.script;
            script.async = true;

            document.head.appendChild(script);
          } else {
            let parser = new DOMParser();
            const insert = (str, index, value) => {
              return str.substr(0, index) + value + str.substr(index);
            };

            const doc = parser.parseFromString(
              insert(el.script, el.script.indexOf('<script') + 7, ` id="${el.name}"`),
              'text/html',
            );

            doc.head.childNodes.forEach((el, i) => {
              /**1 = <script />, 8= comments */
              if (el.nodeType === 1) {
                const existingScript = document.getElementById(el.id || i);
                if (existingScript) document.head.removeChild(existingScript);

                const script = document.createElement('script');

                script.id = el?.id || i;
                script.type = el?.type || 'text/javascript';
                script.innerHTML = el?.textContent || '';
                script.async = !!el?.async;

                document.head.appendChild(script);
              }
            });
          }
        } else if (el.type === 'body') {
          const existingScript = document.getElementById(el.name);
          if (existingScript) document.body.removeChild(existingScript);

          const htmlObject = document.createElement('div');
          htmlObject.id = el.name;
          htmlObject.innerHTML = el.script;

          document.body.appendChild(htmlObject);
        }
      });
    }
  }, [user_detail]);

  const headerStyle = {
    background: user_detail?.topbar_color,
  };

  const resizeCodeForm = () => {
    if (window.innerWidth <= 991) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  };

  const contactPhone = () => {
    if (Array.isArray(user_detail?.contactPhone)) {
      return user_detail?.contactPhone?.map((el, i) => (
        <span className="text" key={i}>
          {el?.label} ( {el.zone} ): <br />
          <a className="phone" href={'tel:' + el?.phone}>
            {el?.phone}
          </a>
          <br />
        </span>
      ));
    } else {
      return (
        <span className="text">
          {t('Phone number:')} <br />
          <a className="phone" href={'tel:' + user_detail?.contactPhone}>
            {user_detail?.contactPhone}
          </a>
          <br />
        </span>
      );
    }
  };

  const contactEmail = () => {
    if (Array.isArray(user_detail?.contactEmail)) {
      return user_detail?.contactEmail?.map((el, i) => (
        <span className="text" key={i}>
          {t('Contact email:')}{' '}
          <a className="email" href={'mailto:' + el}>
            {el}
          </a>
          <br />
        </span>
      ));
    }
  };

  const buttonsSocialNetwors = () => {
    if (Array.isArray(user_detail?.socialNetworks)) {
      return user_detail?.socialNetworks?.map((el, i) => (
        <Button
          key={i}
          size="large"
          type="default"
          shape="circle"
          icon={iconSocialNetwor(el.network)}
          href={el.link}
          target="_blank"
          style={{ marginTop: '10px' }}
        />
      ));
    }
  };

  const iconSocialNetwor = network => {
    switch (network) {
      case 'facebook':
        return <FacebookOutlined />;
      case 'google':
        return <GooglePlusOutlined />;
      case 'twitter':
        return <TwitterOutlined />;
      case 'linkedin':
        return <LinkedinOutlined />;
      case 'youtube':
        return <YoutubeOutlined />;
      default:
        break;
    }
  };

  const lang = () => {
    return (
      <div style={!isMobile ? {} : { marginTop: '-5px' }}>
        <span
          onClick={() => changeLanguage('en-CA')}
          className={`en ${i18n.language === 'en-CA' || i18n.language !== 'fr-CA' ? 'actived' : ''}`}
        >
          EN
        </span>
        <span onClick={() => changeLanguage('fr-CA')} className={`fr ${i18n.language === 'fr-CA' ? 'actived' : ''}`}>
          FR
        </span>
      </div>
    );
  };

  const handleSubmit = async data => {
    const response = await dispatch(registerUserByCode({ ...data, password: 12345 }));

    if (response) {
      sessionStorage.setItem('isloginsuccess', true);
      history.push('/login');
    }
  };

  const logout = () => {
    localStorage.removeItem('cf_email_customer');
    localStorage.removeItem('cf_customer');
    sessionStorage.removeItem('cf_orderIdEditByAdmin');
    sessionStorage.removeItem('cf_aid');
    dispatch(cleanProfileCustomer());
    setCustomerToken('');
    history.push('/');
  };

  return (
    <DivEcommerce primary={user_detail.topbarColor} secondary={user_detail.footerbarColor}>
      <AlertResponse title={user_detail.titlePage} />
      {loadingUser ? (
        <Spin className={'spinner'} />
      ) : (
        <>
          <div className={'navbar'} style={headerStyle}>
            <Menu
              className={'bigmenu'}
              onClick={handleClick}
              selectedKeys={[state.current]}
              mode="horizontal"
              items={[
                {
                  label: (
                    <a
                      href={user_detail?.main_site || '/'}
                      target={user_detail?.main_site !== '/' && '_blank'}
                      rel="noopener noreferrer"
                    >
                      <img src={user_detail?.logo} alt="logo" />
                    </a>
                  ),
                  key: 'logo',
                  className: 'logo',
                },
                !isMobile
                  ? {
                      label: (
                        <div key="phone">
                          <a href={'tel:' + user_detail?.contactPhone?.[0]?.phone || user_detail?.contactPhone}>
                            <PhoneFilled /> {user_detail?.contactPhone?.[0]?.phone || user_detail?.contactPhone}
                          </a>
                        </div>
                      ),
                      key: 'phone',
                      className: 'phone noprint',
                    }
                  : null,

                user_detail.currency_type === CURRENCY_TYPES.CAD
                  ? {
                      label: lang(),
                      key: 'lang',
                      className: 'lang noprint',
                    }
                  : null,

                !isMobile
                  ? {
                      label: (
                        <Link to="/login">
                          <UserOutlined style={{ paddingRight: '3px' }} />
                        </Link>
                      ),
                      key: 'profile',
                      className: 'actions noprint',
                      style: { width: '40px' },
                    }
                  : null,

                {
                  label: (
                    <Badge size="small" count={orderData?.products?.length} showZero>
                      <Link to={'/cart'}>
                        <ShoppingCartOutlined style={{ paddingRight: '3px' }} />
                      </Link>
                    </Badge>
                  ),
                  key: 'shopping',
                  className: 'actions noprint',
                  style: { width: '60px' },
                },

                customerToken
                  ? {
                      label: (
                        <Tooltip title={t('Logout')}>
                          {' '}
                          <LogoutOutlined onClick={logout} />
                        </Tooltip>
                      ),
                      key: 'logout',
                      className: 'actions noprint',
                    }
                  : null,
              ]}
            />
          </div>

          <div className={'container'}>{WrappedComponent}</div>

          <Footer className="footer noprint">
            <Col span={24}>
              <Row gutter={15}>
                <Col xs={24} md={{ span: 6, push: 2 }}>
                  <div className="col-footer text-center">
                    <a href={user_detail?.main_site || '/'} target="_blank" rel="noopener noreferrer">
                      <img src={user_detail?.logo} alt="logo" />
                    </a>
                  </div>

                  <div className="col-footer" style={{ display: 'inline-flex' }}>
                    <div style={{ padding: '10px' }}>
                      <PushpinOutlined
                        style={{
                          color: 'white',
                          border: '1px solid',
                          padding: '10px',
                          borderRadius: '20px',
                          fontSize: '15px',
                        }}
                      />{' '}
                    </div>
                    <div style={{ lineHeight: '25px' }}>
                      <span className="text">{`${user_detail?.address_line_1 || ''} ${user_detail?.city ||
                        ''}, ${user_detail?.state_code || ''} ${user_detail?.postal_code ||
                        ''}, ${user_detail?.country || ''}`}</span>
                    </div>
                  </div>

                  <div className="col-footer" style={{ display: 'inline-flex' }}>
                    {Array.isArray(user_detail?.contactEmail) && user_detail?.contactEmail.length > 0 ? (
                      <>
                        <div style={{ padding: '0 10px' }}>
                          <MailFilled
                            style={{
                              color: 'white',
                              border: '1px solid',
                              padding: '10px',
                              borderRadius: '20px',
                              fontSize: '15px',
                            }}
                          />
                        </div>
                        <div className="emails">{contactEmail()}</div>
                      </>
                    ) : (
                      <></>
                    )}
                  </div>
                </Col>
                <Col xs={24} lg={{ span: 12, push: 6 }} xl={{ span: 8, push: 10 }}>
                  {customerToken ? (
                    <></>
                  ) : (
                    <Form name="login" onFinish={handleSubmit}>
                      <Form.Item
                        name="value"
                        rules={[
                          { required: true, message: t('Please input your email or phone!') },
                          () => ({
                            validator(_rule, value) {
                              if (
                                !value ||
                                /^([a-zA-Z0-9_\.\-+])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})|([0-9]{10,})+$/.test(
                                  value,
                                )
                              ) {
                                return Promise.resolve();
                              } else {
                                return Promise.reject(
                                  t(
                                    'Mail Address / Phone number is not valid, Please provide a valid Email or Phone number',
                                  ),
                                );
                              }
                            },
                            validateTrigger: 'onSubmit',
                          }),
                        ]}
                      >
                        <Input
                          style={{ maxWidth: isMobile ? '13rem' : '14rem', marginTop: '40px' }}
                          placeholder="Phone number or email"
                          type="text"
                          suffix={
                            <Button
                              style={{
                                position: 'absolute',
                                height: '48px',
                              }}
                              type="primary"
                              htmlType="submit"
                            >
                              SIGN UP
                            </Button>
                          }
                        />
                      </Form.Item>
                    </Form>
                  )}
                  <div className="col-footer" style={{ color: 'white' }}>
                    {Array.isArray(user_detail?.socialNetworks) && user_detail?.socialNetworks.length > 0 ? (
                      <>
                        {t('Follow us on')}: {buttonsSocialNetwors()}
                      </>
                    ) : (
                      <></>
                    )}
                  </div>
                  <br />

                  <Row>
                    <Col span={24}>
                      <div className="col-footer" style={{ display: 'inline-flex' }}>
                        <div style={{ padding: '10px' }}>
                          <PhoneFilled
                            style={{
                              color: 'white',
                              border: '1px solid',
                              padding: '10px',
                              borderRadius: '20px',
                              fontSize: '15px',
                            }}
                          />
                        </div>
                        <div>{contactPhone()}</div>
                      </div>
                    </Col>
                  </Row>

                  <div style={{ color: 'white', textAlign: 'right' }}>
                    {t('Currency type')}: {user_detail?.currency_type}
                  </div>
                </Col>
              </Row>
            </Col>
          </Footer>
        </>
      )}
    </DivEcommerce>
  );
};

export default WithEcommerceLayout;
