import actions from './actions';

const { GET_RESELLERS, GET_RESELLER_BY_ID, CLEAN_RESELLER, ACTION_BEGIN, ERROR } = actions;

const initialState = {
  resellers: {},
  reseller: {},

  customers: {},

  loadingCustomers: false,
  loadingResellers: false,
  loadingBalance: false,
  loadingPaymentReseller: false,
  error: null,
};

const resellerReducer = (state = initialState, action) => {
  const { type, data, err } = action;

  switch (type) {
    case GET_RESELLERS:
      return {
        ...state,
        resellers: data,
        loadingResellers: false,
      };
    case GET_RESELLER_BY_ID:
      return {
        ...state,
        reseller: data.reseller,
        loadingResellers: false,
      };
    case CLEAN_RESELLER:
      return {
        ...state,
        reseller: {},
        loadingResellers: false,
      };
    case ACTION_BEGIN:
      const stateClone = { ...state, ...data };
      return {
        ...stateClone,
      };
    case ERROR:
      return {
        ...state,
        error: err,
        loadingResellers: false,
        loadingCustomers: false,
        loadingBalance: false,
        loadingPaymentReseller: false,
      };
    default:
      return state;
  }
};

export default resellerReducer;
