const actions = {
  GET_ACTIVITY_TYPES: 'GET_ACTIVITY_TYPES',
  GET_ACTIVITY_TYPE_BY_ID: 'GET_ACTIVITY_TYPE_BY_ID',
  CLEAN_ACTIVITY_TYPE: 'CLEAN_ACTIVITY_TYPE',

  GET_COMPANIES: 'GET_COMPANIES',
  GET_COMPANY_BY_ID: 'GET_COMPANY_BY_ID',
  CLEAN_COMPANY: 'CLEAN_COMPANY',

  GET_CONTACT_TYPES: 'GET_CONTACT_TYPES',
  GET_CONTACT_TYPE_BY_ID: 'GET_CONTACT_TYPE_BY_ID',
  CLEAN_CONTACT_TYPE: 'CLEAN_CONTACT_TYPE',
 
  GET_LANGUAGES: 'GET_LANGUAGES',
  GET_LANGUAGE_BY_ID: 'GET_LANGUAGE_BY_ID',
  CLEAN_LANGUAGE: 'CLEAN_LANGUAGE',

  GET_ADDRESS_BOOKS: 'GET_ADDRESS_BOOKS',
  GET_ADDRESS_BOOK_BY_ID: 'GET_ADDRESS_BOOK_BY_ID',
  CLEAN_ADDRESS_BOOK: 'CLEAN_ADDRESS_BOOK',

  GET_USERS: 'GET_USERS',
  GET_USER_BY_ID: 'GET_USER_BY_ID',
  CLEAN_USER: 'CLEAN_USER',

  BEGIN: 'BEGIN',
  ERROR: 'ERROR',

  activityTypesSuccess: data => {
    return { type: actions.GET_ACTIVITY_TYPES, data };
  },
  activityTypeSuccess: data => {
    return { type: actions.GET_ACTIVITY_TYPE_BY_ID, data };
  },
  cleanActivityTypeAction: () => {
    return { type: actions.CLEAN_ACTIVITY_TYPE };
  },

  companiesSuccess: data => {
    return { type: actions.GET_COMPANIES, data };
  },
  companySuccess: data => {
    return { type: actions.GET_COMPANY_BY_ID, data };
  },
  cleanCompanyAction: () => {
    return { type: actions.CLEAN_COMPANY };
  },
  contactTypesSuccess: data => {
    return { type: actions.GET_CONTACT_TYPES, data };
  },
  contactTypeSuccess: data => {
    return { type: actions.GET_CONTACT_TYPE_BY_ID, data };
  },
  cleanContactTypeAction: () => {
    return { type: actions.CLEAN_CONTACT_TYPE };
  },

  languagesSuccess: data => {
    return { type: actions.GET_LANGUAGES, data };
  },
  languageSuccess: data => {
    return { type: actions.GET_LANGUAGE_BY_ID, data };
  },
  cleanLanguageAction: () => {
    return { type: actions.CLEAN_LANGUAGE };
  },

  addressBooksSuccess: data => {
    return { type: actions.GET_ADDRESS_BOOKS, data };
  },
  addressBookSuccess: data => {
    return { type: actions.GET_ADDRESS_BOOK_BY_ID, data };
  },
  cleanAddressBookAction: () => {
    return { type: actions.CLEAN_ADDRESS_BOOK };
  },

  usersSuccess: data => {
    return { type: actions.GET_USERS, data };
  },
  userSuccess: data => {
    return { type: actions.GET_USER_BY_ID, data };
  },
  cleanUserAction: () => {
    return { type: actions.CLEAN_USER };
  },

  begin: () => { return { type: actions.BEGIN } },
  error: err => { return { type: actions.ERROR, err } }
};

export default actions; 
