import actions from './actions';

const { ACTION_BEGIN, GET_USERS, GET_USER_BY_ID, CLEAN_USER, ERROR } = actions;

const initialState = {
  users: [],
  user: {},

  loadingUsers: false,
  error: null,
};

const productReducerNew = (state = initialState, action) => {
  const { type, data, err } = action;

  switch (type) {
    case ACTION_BEGIN:
      const stateClone = { ...state, ...data };
      return {
        ...stateClone,
      };
    case GET_USERS:
      return {
        ...state,
        users: data.users,
      };
    case GET_USER_BY_ID:
      return {
        ...state,
        user: data.user,
        loadingUsers: false,
      };
    case CLEAN_USER:
      return {
        ...state,
        user: {},
      };

    case ERROR:
      return {
        ...state,
        error: err,
        loadingUsers: false,
      };
    default:
      return state;
  }
};

export default productReducerNew;
