const actions = {
  GET_ITEM_TEMPLATES: 'GET_ITEM_TEMPLATES',
  GET_ITEM_TEMPLATE_BY_ID: 'GET_ITEM_TEMPLATE_BY_ID',
  CLEAN_ITEM_TEMPLATE: 'CLEAN_ITEM_TEMPLATE',

  GET_ITEMS: 'GET_ITEMS',
  GET_ITEM_BY_ID: 'GET_ITEM_BY_ID',
  CLEAN_ITEM: 'CLEAN_ITEM',

  GET_ITEM_TYPES: 'GET_ITEM_TYPES',
  GET_ITEM_TYPE_BY_ID: 'GET_ITEM_TYPE_BY_ID',
  CLEAN_ITEM_TYPE: 'CLEAN_ITEM_TYPE',

  GET_SOFTWARE_TYPES: 'GET_SOFTWARE_TYPES',
  GET_SOFTWARE_TYPE_BY_ID: 'GET_SOFTWARE_TYPE_BY_ID',
  CLEAN_SOFTWARE_TYPE: 'CLEAN_SOFTWARE_TYPE',

  GET_MATERIALS_AVAILABLES: 'GET_MATERIALS_AVAILABLES',

  GET_FORMS_CATALOG: 'GET_FORMS_CATALOG',
  GET_FORM_BY_ID: 'GET_FORM_BY_ID',
  CLEAN_FORM: 'CLEAN_FORM',

  ERROR: 'ERROR',

  itemTemplatesSuccess: data => {
    return { type: actions.GET_ITEM_TEMPLATES, data };
  },
  itemTemplateSuccess: data => {
    return { type: actions.GET_ITEM_TEMPLATE_BY_ID, data };
  },
  cleanItemTemplateAction: () => {
    return { type: actions.CLEAN_ITEM_TEMPLATE };
  },

  itemsSuccess: data => {
    return { type: actions.GET_ITEMS, data };
  },
  itemSuccess: data => {
    return { type: actions.GET_ITEM_BY_ID, data };
  },
  cleanItemAction: () => {
    return { type: actions.CLEAN_ITEM };
  },

  itemTypesSuccess: data => {
    return { type: actions.GET_ITEM_TYPES, data };
  },
  itemTypeSuccess: data => {
    return { type: actions.GET_ITEM_TYPE_BY_ID, data };
  },
  cleanItemTypeAction: () => {
    return { type: actions.CLEAN_ITEM_TYPE };
  },
  materialsAvailablesSuccess: data => {
    return { type: actions.GET_MATERIALS_AVAILABLES, data };
  },
  formsCatalogSuccess: data => {
    return { type: actions.GET_FORMS_CATALOG, data };
  },
  getFormByIdSuccess: data => {
    return { type: actions.GET_FORM_BY_ID, data };
  },
  cleanFormSuccess: () => {
    return { type: actions.CLEAN_FORM };
  },

  softwareTypesSuccess: data => {
    return { type: actions.GET_SOFTWARE_TYPES, data };
  },
  softwareTypeSuccess: data => {
    return { type: actions.GET_SOFTWARE_TYPE_BY_ID, data };
  },
  cleanSoftwareTypeAction: () => {
    return { type: actions.CLEAN_SOFTWARE_TYPE };
  },

  error: err => {
    return { type: actions.ERROR, err };
  },
};

export default actions;
