import actions from './actions';

const {
  GET_SOFTWARE_TYPES,
  GET_SOFTWARE_TYPE_BY_ID,
  CLEAN_SOFTWARE_TYPE,

  GET_ITEM_TEMPLATES,
  GET_ITEM_TEMPLATE_BY_ID,
  CLEAN_ITEM_TEMPLATE,
  GET_ITEMS,
  GET_ITEM_BY_ID,
  CLEAN_ITEM,
  GET_ITEM_TYPES,
  GET_ITEM_TYPE_BY_ID,
  CLEAN_ITEM_TYPE,
  GET_MATERIALS_AVAILABLES,
  GET_FORMS_CATALOG,
  GET_FORM_BY_ID,
  CLEAN_FORM,
  ERROR,
} = actions;

const initialState = {
  itemTemplates: {},
  itemTemplate: {},
  items: {},
  item: {},
  itemTypes: {},
  itemType: {},
  materialsAvailables: {},
  itemTypesSelectbox: [],
  itemTemplatesSelectbox: [],
  formsCatalog: [],
  formsCatalogSelectbox: [],
  formObject: {},

  softwareTypes: {},
  softwareType: {},

  error: null,
};

const itemReducer = (state = initialState, action) => {
  const { type, data, err } = action;

  switch (type) {
    case GET_SOFTWARE_TYPES:
      return {
        ...state,
        softwareTypes: data.softwareTypes,
      };
    case GET_SOFTWARE_TYPE_BY_ID:
      return {
        ...state,
        softwareType: data.softwareType,
      };
    case CLEAN_SOFTWARE_TYPE:
      return {
        ...state,
        softwareType: {},
      };

    case GET_ITEM_TEMPLATES:
      return {
        ...state,
        itemTemplates: data.itemTemplates,
        itemTemplatesSelectbox: data.itemTemplatesSelectbox,
        loading: false,
      };
    case GET_ITEM_TEMPLATE_BY_ID:
      return {
        ...state,
        itemTemplate: data.itemTemplate,
        loading: false,
      };
    case CLEAN_ITEM_TEMPLATE:
      return {
        ...state,
        itemTemplate: {},
      };
    case GET_ITEMS:
      return {
        ...state,
        items: data.items,
        loading: false,
      };
    case GET_ITEM_BY_ID:
      return {
        ...state,
        item: data.item,
        loading: false,
      };
    case CLEAN_ITEM:
      return {
        ...state,
        item: {},
      };
    case GET_ITEM_TYPES:
      return {
        ...state,
        itemTypes: data.itemTypes,
        itemTypesSelectbox: data.itemTypesSelectbox,
        loading: false,
      };
    case GET_ITEM_TYPE_BY_ID:
      return {
        ...state,
        itemType: data.itemType,
        loading: false,
      };
    case CLEAN_ITEM_TYPE:
      return {
        ...state,
        itemType: {},
        materialsAvailables: {},
      };
    case GET_MATERIALS_AVAILABLES:
      return {
        ...state,
        materialsAvailables: data.materials,
      };
    case GET_FORMS_CATALOG:
      return {
        ...state,
        formsCatalog: data.formsCatalog,
        formsCatalogSelectbox: data.formsCatalogSelectbox,
      };
    case GET_FORM_BY_ID:
      return {
        ...state,
        formObject: data.form,
      };
    case CLEAN_FORM:
      return {
        ...state,
        formObject: {},
      };
    case ERROR:
      return {
        ...state,
        error: err,
        loading: false,
      };
    default:
      return state;
  }
};

export default itemReducer;
