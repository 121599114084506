import React, { lazy, Suspense } from 'react';
import { Spin } from 'antd';
import { Switch, Route, useRouteMatch } from 'react-router-dom';
import AuthLayout from '../container/profile/authentication/Index';
import ScrollToTop from './scrollToTop';

const Login = lazy(() => import('../container/profile/authentication/overview/SignIn'));
const SignUp = lazy(() => import('../container/profile/authentication/overview/Signup'));
const ForgotPass = lazy(() => import('../container/profile/authentication/overview/ForgotPassword'));
const ActivateTwoFactor = lazy(() => import('../container/profile/authentication/overview/ActivateTwoFactor'));
const RequestTwoFactorCode = lazy(() => import('../container/profile/authentication/overview/RequestTwoFactorCode'));
const SignupSuccess = lazy(() => import('../container/profile/authentication/overview/SignupSuccess'));
const RequestPasswordResetSuccess = lazy(() =>
  import('../container/profile/authentication/overview/RequestPasswordResetSuccess'),
);
const PasswordReset = lazy(() => import('../container/profile/authentication/overview/PasswordReset'));

const FrontendRoute = () => {
  let { path } = useRouteMatch();

  return (
    <AuthLayout
      WrappedComponent={
        <>
          <ScrollToTop />
          <Switch>
            <Suspense
              fallback={
                <div className="spin">
                  <Spin />
                </div>
              }
            >
              <Route exact path={path} component={Login} />
              <Route path={`${path}/register`} component={SignUp} />
              <Route path={`${path}/accountCreated`} component={SignupSuccess} />
              <Route path={`${path}/activateTwoFactorAuth`} component={ActivateTwoFactor} />
              <Route path={`${path}/requestTwoFactorAuthCode`} component={RequestTwoFactorCode} />
              <Route path={`${path}/forgotPassword`} component={ForgotPass} />
              <Route path={`${path}/requestPasswordResetSuccess`} component={RequestPasswordResetSuccess} />
              <Route path={`${path}/reset-password/:token`} component={PasswordReset} />
            </Suspense>
          </Switch>
        </>
      }
    />
  );
};

export default FrontendRoute;
