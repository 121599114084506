import actions from './actions';

const {
  ACTION_BEGIN,
  GET_PRODUCTS,
  GET_PRODUCT_BY_ID,
  CLEAN_PRODUCT,
  GET_ITEMS_AVAILABLES,
  GET_ADDONS_AVAILABLES,
  GET_PRODUCT_PRICES,
  GET_PRODUCT_PRICE_BY_ID,
  CLEAN_PRODUCT_PRICE,
  GET_CATEGORIES,
  GET_CATEGORY_BY_ID,
  CLEAN_CATEGORY,

  GET_COLORS,
  GET_COLOR_BY_ID,
  CLEAN_COLOR,

  GET_PROMO_CODES,
  GET_PROMO_CODE_BY_ID,
  CLEAN_PROMO_CODE,

  ERROR,
} = actions;

const initialState = {
  products: {},
  product: {},
  itemsAvailables: {},
  addonsAvailables: {},
  categories: {},
  category: {},
  colors: {},
  color: {},
  promoCodes: {},
  promoCode: {},
  productPrices: {},
  productPrice: {},
  loadingProduct: false,
  loadingColors: false,
  loadingPromoCodes: false,

  error: null,
};

const productReducerNew = (state = initialState, action) => {
  const { type, data, err } = action;

  switch (type) {
    case ACTION_BEGIN:
      const stateClone = { ...state, ...data };
      return {
        ...stateClone,
      };
    case GET_PRODUCTS:
      return {
        ...state,
        products: data.products,
      };
    case GET_PRODUCT_BY_ID:
      return {
        ...state,
        product: data.product,
        loadingProduct: false,
      };
    case CLEAN_PRODUCT:
      return {
        ...state,
        product: {},
        itemsAvailables: {},
      };
    case GET_ITEMS_AVAILABLES:
      return {
        ...state,
        itemsAvailables: data.items,
      };
    case GET_ADDONS_AVAILABLES:
      return {
        ...state,
        addonsAvailables: data.addons,
      };
    case GET_PRODUCT_PRICES:
      return {
        ...state,
        productPrices: data.productPrices,
      };
    case GET_PRODUCT_PRICE_BY_ID:
      return {
        ...state,
        productPrice: data.productPrice,
      };
    case CLEAN_PRODUCT_PRICE:
      return {
        ...state,
        productPrice: {},
      };

    case GET_CATEGORIES:
      return {
        ...state,
        categories: data.categories,
      };
    case GET_CATEGORY_BY_ID:
      return {
        ...state,
        category: data.category,
      };
    case CLEAN_CATEGORY:
      return {
        ...state,
        category: {},
      };
    case GET_COLORS:
      return {
        ...state,
        colors: data.colors,
      };
    case GET_COLOR_BY_ID:
      return {
        ...state,
        color: data.color,
      };
    case CLEAN_COLOR:
      return {
        ...state,
        color: {},
      };

    case GET_PROMO_CODES:
      return {
        ...state,
        promoCodes: data.promoCodes,
      };
    case GET_PROMO_CODE_BY_ID:
      return {
        ...state,
        promoCode: data.promoCode,
      };
    case CLEAN_PROMO_CODE:
      return {
        ...state,
        promoCode: {},
      };

    case ERROR:
      return {
        ...state,
        error: err,
        loadingProduct: false,
        loadingColors: false,
        loadingPromoCodes: false,
      };
    default:
      return state;
  }
};

export default productReducerNew;
