import Cookies from 'js-cookie';
import actions from './actions';

const { LOGIN_SUCCESS, LOGOUT_SUCCESS } = actions;

const initState = {
  login: Cookies.get('cf_logedIn'),
  user: Cookies.getJSON('cf_user'),
};

/**
 *
 * @todo impure state mutation/explaination
 */
const AuthReducer = (state = initState, action) => {
  const { type, data } = action;
  switch (type) {
    case LOGIN_SUCCESS:
      return {
        ...state,
        login: data,
      };
    case LOGOUT_SUCCESS:
      return {
        ...state,
        login: data,
      };
    default:
      return state;
  }
};
export default AuthReducer;
