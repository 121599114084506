const actions = {
  SUCCESS_MAILTEMPLATES: 'SUCCESS_MAILTEMPLATES',
  ACTION_BEGIN: 'ACTION_BEGIN',
  ERROR: 'ERROR',

  successMailTemplates: data => {
    return { type: actions.SUCCESS_MAILTEMPLATES, data };
  },
  actionBegin: data => {
    return { type: actions.ACTION_BEGIN, data };
  },
  error: err => {
    return { type: actions.ERROR, err };
  },
};

export default actions;
