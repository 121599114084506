import React from 'react';
import { Row, Col } from 'antd';
import { Aside, Content } from './overview/style';
import Heading from '../../../components/heading/heading';

const AuthLayout = ({ WrappedComponent }) => {
  document.getElementById('pageTitle').innerText = 'Cheques Fulfillment';

  return (
    <Row>
      <Col xxl={8} xl={9} lg={12} md={8} xs={24}>
        <Aside>
          <div className="auth-side-content">
            <Content>
              <Heading as="h5" className="color-primary text-center">
                Proudly Canadian
                <img src={require('../../../static/img/auth/canadian-leaf.png')} height="32" alt="..." />
                Canadian Dollar
              </Heading>
              <br />
              <img className="auth-content-figure" src={require('../../../static/img/Logo_Dark.png')} alt="..." />
            </Content>
          </div>
        </Aside>
      </Col>

      <Col xxl={16} xl={15} lg={12} md={16} xs={24}>
        {WrappedComponent}
      </Col>
    </Row>
  );
};

export default AuthLayout;
