const actions = {

  GET_RESELLERS: 'GET_RESELLERS', 
  GET_RESELLER_BY_ID: 'GET_RESELLER_BY_ID', 
  CLEAN_RESELLER: 'CLEAN_RESELLER', 
  ACTION_BEGIN: 'ACTION_BEGIN',
  ERROR: 'ERROR',

  resellersSuccess: data => {
    return { type: actions.GET_RESELLERS, data };
  },
  resellerSuccess: data => {
    return { type: actions.GET_RESELLER_BY_ID, data };
  },
  cleanResellerAction: () => {
    return { type: actions.CLEAN_RESELLER };
  },

  actionBegin: data => { return { type: actions.ACTION_BEGIN, data } },
  error: err => { return { type: actions.ERROR, err } }
};

export default actions;
