const actions = {
  GET_PRODUCTS: 'GET_PRODUCTS',
  GET_PRODUCT_BY_ID: 'GET_PRODUCT_BY_ID',
  CLEAN_PRODUCT: 'CLEAN_PRODUCT',
  GET_ITEMS_AVAILABLES: 'GET_ITEMS_AVAILABLES',
  GET_ADDONS_AVAILABLES: 'GET_ADDONS_AVAILABLES',

  GET_PRODUCT_PRICES: 'GET_PRODUCT_PRICES',
  GET_PRODUCT_PRICE_BY_ID: 'GET_PRODUCT_PRICE_BY_ID',
  CLEAN_PRODUCT_PRICE: 'CLEAN_PRODUCT_PRICE',

  GET_COLORS: 'GET_COLORS',
  GET_COLOR_BY_ID: 'GET_COLOR_BY_ID',
  CLEAN_COLOR: 'CLEAN_COLOR',

  GET_PROMO_CODES: 'GET_PROMO_CODES',
  GET_PROMO_CODE_BY_ID: 'GET_PROMO_CODE_BY_ID',
  CLEAN_PROMO_CODE: 'CLEAN_PROMO_CODE',

  GET_CATEGORIES: 'GET_CATEGORIES',
  GET_CATEGORY_BY_ID: 'GET_CATEGORY_BY_ID',
  CLEAN_CATEGORY: 'CLEAN_CATEGORY',
  ACTION_BEGIN: 'ACTION_BEGIN',

  ERROR: 'ERROR',

  actionBegin: data => {
    return { type: actions.ACTION_BEGIN, data };
  },

  productsSuccess: data => {
    return { type: actions.GET_PRODUCTS, data };
  },
  productSuccess: data => {
    return { type: actions.GET_PRODUCT_BY_ID, data };
  },
  cleanProductAction: () => {
    return { type: actions.CLEAN_PRODUCT };
  },
  itemsAvailablesSuccess: data => {
    return { type: actions.GET_ITEMS_AVAILABLES, data };
  },
  addonsAvailablesSuccess: data => {
    return { type: actions.GET_ADDONS_AVAILABLES, data };
  },
  productPricesSuccess: data => {
    return { type: actions.GET_PRODUCT_PRICES, data };
  },
  productPriceSuccess: data => {
    return { type: actions.GET_PRODUCT_PRICE_BY_ID, data };
  },
  cleanProductPriceAction: () => {
    return { type: actions.CLEAN_PRODUCT_PRICE };
  },

  categoriesSuccess: data => {
    return { type: actions.GET_CATEGORIES, data };
  },
  categorySuccess: data => {
    return { type: actions.GET_CATEGORY_BY_ID, data };
  },
  cleanCategoryAction: () => {
    return { type: actions.CLEAN_CATEGORY };
  },

  colorsSuccess: data => {
    return { type: actions.GET_COLORS, data };
  },
  colorSuccess: data => {
    return { type: actions.GET_COLOR_BY_ID, data };
  },
  cleanColorAction: () => {
    return { type: actions.CLEAN_COLOR };
  },

  promoCodesSuccess: data => {
    return { type: actions.GET_PROMO_CODES, data };
  },
  promoCodeSuccess: data => {
    return { type: actions.GET_PROMO_CODE_BY_ID, data };
  },
  cleanPromoCodeAction: () => {
    return { type: actions.CLEAN_PROMO_CODE };
  },

  error: err => {
    return { type: actions.ERROR, err };
  },
};

export default actions;
