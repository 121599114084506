import React, { useEffect, useState } from 'react';
import { ErrorWrapper, Main } from './styled';
import Heading from '../components/heading/heading';
import publicIp from 'public-ip';

/* eslint-disable react-hooks/exhaustive-deps */
const NotFound = () => {
  const [ip, setIp] = useState('');

  useEffect(async () => {
    try {
      const ip = await publicIp?.v6();
      setIp(ip);
    } catch (error) {
      const ip = await publicIp?.v4();
      setIp(ip);
    }
  }, []);

  return (
    <Main>
      <ErrorWrapper>
        <img src={require(`../static/img/pages/404.svg`)} alt="404" />
        <Heading className="error-text" as="h3">
          404
        </Heading>
        <p>Sorry! the page you are looking for doesn't exist.</p>
        <div hidden>{ip}</div>
        {/*<NavLink to="/admin">
          <Button size="default" type="primary" to="/admin">
            Return Home
          </Button>
        </NavLink>*/}
      </ErrorWrapper>
    </Main>
  );
};

export default NotFound;
