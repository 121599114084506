const actions = {
  GET_COMMERCIAL_GROUPS: 'GET_COMMERCIAL_GROUPS',
  GET_COMMERCIAL_GROUP_BY_ID: 'GET_COMMERCIAL_GROUP_BY_ID',
  CLEAN_COMMERCIAL_GROUP: 'CLEAN_COMMERCIAL_GROUP',
  ERROR: 'ERROR',

  commercialGroupsSuccess: data => {
    return { type: actions.GET_COMMERCIAL_GROUPS, data };
  },
  commercialGroupSuccess: data => {
    return { type: actions.GET_COMMERCIAL_GROUP_BY_ID, data };
  },
  cleanCommercialGroupAction: () => {
    return { type: actions.CLEAN_COMMERCIAL_GROUP };
  },

  error: err => { return { type: actions.ERROR, err } }
};

export default actions;
