const actions = {
  GET_BANKS: 'GET_BANKS',
  GET_BANK_BY_ID: 'GET_BANK_BY_ID',
  CLEAN_BANK: 'CLEAN_BANK',
  ACTION_BEGIN: 'ACTION_BEGIN',
  ERROR: 'ERROR',

  banksSuccess: data => {
    return { type: actions.GET_BANKS, data };
  },
  bankSuccess: data => {
    return { type: actions.GET_BANK_BY_ID, data };
  },
  cleanBankAction: () => {
    return { type: actions.CLEAN_BANK };
  },

  actionBegin: data => {
    return { type: actions.ACTION_BEGIN, data };
  },
  error: err => {
    return { type: actions.ERROR, err };
  },
};

export default actions;
