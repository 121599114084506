import actions from './actions';

const {
  SET_TOKEN,
  SAVE_BUY,
  CLEAN_BUY_ECOMMERCE,
  SAVE_SHIPMENT,
  SAVE_TAX,
  READ_PROFILE_ECOMMERCE,
  GET_CATEGORIES_ECOMMERCE,
  GET_PRODUCTS_ECOMMERCE,
  GET_PRODUCT_BY_ID_ECOMMERCE,
  CLEAN_PRODUCT_ECOMMERCE,
  ACTION_BEGIN,
  ERROR,
  JOB_SESSION,
  SET_CUSTOMER_ID,
  RESPONSE_STATUS_ECOMMERCE,
  CLEAN_RESPONSE_STATUS_ECOMMERCE,
  GET_ADDRESS_BOOKS_ECOMMERCE,
  GET_ADDRESS_BOOK_BY_ID_ECOMMERCE,
  READ_PROFILE_CUSTOMER_ECOMMERCE,
  CLEAN_PROFILE_CUSTOMER_ECOMMERCE,
  CLEAN_ADDRESS_BOOK_ECOMMERCE,

  GET_ORDER_ECOMMERCE,
  GET_ORDERS_ECOMMERCE,
  CLEAN_ORDERS_ECOMMERCE,

  CLEAN_SHIPMENTS,
  CLEAN_TAXES,
} = actions;

const initialState = {
  isSignedIn: false,
  userSettings: {},
  categories: [],
  products: {},
  product: {},
  software: [],
  shipments: [],
  tax: [],
  buy: [],
  loadingTax: false,
  loadingUser: false,
  loadingBuy: false,
  loadingProduct: false,
  loadingLogin: false,
  error: null,

  jobSession: {}, // This object contains all the Cpress job
  loadingCpress: false,
  customerId: '',
  customer: {},
  customerOrders: {},
  customerOrder: {},
  loadingOrder: false,

  addressBooks: {},
  addressBook: {},
  responseStatus: {},
  loadingAddress: false,
  refreshShipping: false,

  loadingBanking: false,
  loadingCompany: false,
  loadingCoupon: false,
};

const ecommerceReducer = (state = initialState, action) => {
  const { type, data, err } = action;

  switch (type) {
    case SET_TOKEN:
      return {
        ...state,
        isSignedIn: !!data,
      };
    case SAVE_TAX:
      return {
        ...state,
        loadingTax: false,
        tax: data,
      };
    case SAVE_BUY:
      return {
        ...state,
        loadingBuy: false,
        buy: data,
      };
    case SAVE_SHIPMENT:
      return {
        ...state,
        shipments: data,
        refreshShipping: false,
      };
    case READ_PROFILE_ECOMMERCE:
      return {
        ...state,
        userSettings: data,
        loadingUser: false,
      };
    case GET_CATEGORIES_ECOMMERCE:
      return {
        ...state,
        categories: data.categories.data,
      };
    case GET_PRODUCTS_ECOMMERCE:
      return {
        ...state,
        products: data.products,
        loadingProduct: false,
      };
    case GET_PRODUCT_BY_ID_ECOMMERCE:
      return {
        ...state,
        product: data.product,
        software: data.software,
        loadingProduct: false,
      };
    case CLEAN_PRODUCT_ECOMMERCE:
      return {
        ...state,
        product: {},
        software: [],
      };
    case CLEAN_BUY_ECOMMERCE:
      return {
        ...state,
        buy: {},
      };
    case ACTION_BEGIN:
      const stateClone = { ...state, ...data };
      return {
        ...stateClone,
      };
    case JOB_SESSION:
      return {
        ...state,
        jobSession: data,
      };
    case SET_CUSTOMER_ID:
      return {
        ...state,
        customerId: data,
      };
    case RESPONSE_STATUS_ECOMMERCE:
      return {
        ...state,
        responseStatus: action.payload,
      };
    case CLEAN_RESPONSE_STATUS_ECOMMERCE:
      return {
        ...state,
        responseStatus: {},
      };

    case GET_ADDRESS_BOOKS_ECOMMERCE:
      return {
        ...state,
        addressBooks: data.addressBooks,
        loadingAddress: false,
      };
    case GET_ADDRESS_BOOK_BY_ID_ECOMMERCE:
      return {
        ...state,
        addressBook: data.addressBook,
        loadingAddress: false,
      };
    case READ_PROFILE_CUSTOMER_ECOMMERCE:
      return {
        ...state,
        customer: data,
        loadingLogin: false,
      };
    case CLEAN_PROFILE_CUSTOMER_ECOMMERCE:
      return {
        ...state,
        customer: {},
        customerOrders: {},
      };
    case CLEAN_ADDRESS_BOOK_ECOMMERCE:
      return {
        ...state,
        addressBook: {},
      };
    case GET_ORDERS_ECOMMERCE:
      return {
        ...state,
        customerOrders: data,
        loadingOrder: false,
      };
    case GET_ORDER_ECOMMERCE:
      return {
        ...state,
        customerOrder: data,
        loadingOrder: false,
      };
    case CLEAN_ORDERS_ECOMMERCE:
      return {
        ...state,
        customerOrders: {},
      };
    case CLEAN_SHIPMENTS:
      return {
        ...state,
        shipments: [],
      };
    case CLEAN_TAXES:
      return {
        ...state,
        tax: [],
      };
    case ERROR:
      return {
        ...state,
        error: err,
        loadingProduct: false,
        loadingUser: false,
        loadingBuy: false,
        loadingLogin: false,
        loadingAddress: false,
        loadingBanking: false,
        loadingOrder: false,
        loadingCpress: false,
        loadingTax: false,
        loadingCompany: false,
        loadingCoupon: false,
      };
    default:
      return state;
  }
};

export default ecommerceReducer;
