const actions = {
  API_CALL_BEGIN: 'API_CALL_BEGIN',
  CLEAN_RESPONSE_STATUS: 'CLEAN_RESPONSE_STATUS',
  RESPONSE_STATUS: 'RESPONSE_STATUS',

  apiCallBegin: () => {
    return dispatch => {
      dispatch({
        type: actions.API_CALL_BEGIN,
      });
    };
  },

  setResponseStatus: (status, response) => {
    return dispatch => {
      dispatch({
        type: actions.RESPONSE_STATUS,
        payload: { status, response },
      });
    };
  },

  cleanResponseStatus: () => {
    return dispatch => {
      dispatch({
        type: actions.CLEAN_RESPONSE_STATUS,
      });
    };
  },
};

export default actions;
