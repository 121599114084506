import actions from './actions';

const { API_CALL_BEGIN, CLEAN_RESPONSE_STATUS, RESPONSE_STATUS } = actions;

const initialState = {
  responseStatus: {},
  loading: false,
};

export default function responseReducer(state = initialState, action) {
  switch (action.type) {
    case API_CALL_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case RESPONSE_STATUS:
      return {
        ...state,
        responseStatus: action.payload,
        loading: false,
      };
    case CLEAN_RESPONSE_STATUS:
      return {
        ...state,
        responseStatus: {},
        loading: false,
      };
    default:
      return state;
  }
}
