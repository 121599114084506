import React, { useEffect, useState } from 'react';
import { Avatar, Typography } from 'antd';
import { Link, useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import FeatherIcon from 'feather-icons-react';
import { InfoWraper, UserDropDwon } from './auth-info-style';
import { Popover } from '../../popup/popup';
import { logOut } from '../../../redux/authentication/actionCreator';
import Heading from '../../heading/heading';
import Cookies from 'js-cookie';

const { Text } = Typography;

/* eslint-disable react-hooks/exhaustive-deps */
const AuthInfo = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [user, setUser] = useState({});
  const [picture, setPicture] = useState(localStorage.getItem('cf_user_picture'));

  useEffect(() => {
    if (Cookies.get('cf_user'))
      setUser(JSON.parse(Cookies.get('cf_user')));
  }, []);

  useEffect(() => {
    if (!picture && user?.email) {
      setPicture(generteIMG(user?.email?.charAt(0).toUpperCase()));
    }
  }, [user]);

  const SignOut = async e => {
    e.preventDefault();
    await dispatch(logOut());
    history.replace('/auth');
  };

  const labelAccountType = () => {
    let accountType = 'Unknown';
    if (user.role_id === 1) accountType = 'Internal user';
    if (user.role_id === 2) accountType = 'Reseller';
    return <>{accountType}</>;
  };

  const generteIMG = item => {
    const canvas = document.createElement('canvas');
    canvas.width = 96;
    canvas.height = 96;

    const context = canvas.getContext('2d');
    context.beginPath();
    context.rect(5, 2, 190, 55);
    context.fillStyle = '#bf1f2e';
    context.fill();
    context.lineWidth = 96;
    context.strokeStyle = '#2c75b0';
    context.stroke();

    const x = canvas.width / 2;
    const y = canvas.height / 2;
    context.font = '40pt Inter';
    context.textAlign = 'center';
    context.fillStyle = 'white';
    context.fillText(item, x, y + 22);

    return context.canvas.toDataURL();
  };

  const userContent = (
    <UserDropDwon>
      <div className="user-dropdwon">
        <figure className="user-dropdwon__info">
          <img src={picture || ''} width="46" alt="" />
          <figcaption>
            <Heading as="h5">
              <Text style={{ width: 180 }} ellipsis title={user.email}>
                {user.email}
              </Text>
            </Heading>
            <p>{labelAccountType()}</p>
          </figcaption>
        </figure>
        {user.role_id === 2 ? (
          <ul className="user-dropdwon__links">
            <li>
              <Link to="/admin/profile/myProfile/website-settings">
                <FeatherIcon icon="settings" /> My profile
              </Link>
            </li>
          </ul>
        ) : (
          <></>
        )}
        <Link className="user-dropdwon__bottomAction" onClick={SignOut} to="#">
          <FeatherIcon icon="log-out" /> Sign Out
        </Link>
      </div>
    </UserDropDwon>
  );

  return (
    <InfoWraper>
      <div className="nav-author">
        <Popover placement="bottomRight" content={userContent} action="click">
          <Link to="#" className="head-example">
            <Avatar src={picture || ''} />
          </Link>
        </Popover>
      </div>
    </InfoWraper>
  );
};

export default AuthInfo;
